import { Box, CircularProgress, Grid } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { getMyPaymentsClientInfo } from "../../api/my-payments-api";
import { useSelector } from "react-redux";

function FormUserPublicData() {
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const clientId = useSelector((state) => state.myPayments.id);

  const getInitialValues = async () => {
    try {
      const response = await getMyPaymentsClientInfo(clientId);

      setInitialValues(response.data);
      setShowForm(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (!initialValues) {
    getInitialValues();
  }

  return (
    <React.Fragment>
      {!showForm && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {showForm && (
        <Formik
          initialValues={initialValues}
          validationSchema={{}}
          onSubmit={() => { }}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={3}>
                {!values.auto && ( <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    name="identifier"
                    label="Matricula"
                    helperText="Correo electrónico o mátricula del solicitante"
                    disabled
                  />
                </Grid>)}

                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    name="fullname"
                    label="Nombre"
                    helperText="Nombre del solicitante"
                    disabled
                  />
                </Grid>

                {!values.auto && values.academicprogram !== "" && (<Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    name="academicprogram"
                    label="Programa de estudio"
                    helperText="Programa de estudio del solicitante"
                    variant="standard"
                    disabled
                  />
                </Grid>)}

                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="email"
                    name="email"
                    label="Correo electrónico"
                    helperText="Correo electrónico del solicitante"
                    disabled
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
}

export { FormUserPublicData };

import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Button
} from "@material-ui/core";
import React from "react";
import { getSessionToken } from '../../../actions/auth-actions';
import { getAllConcept } from '../../../api/concepts-api';
import GenericTable from "../../ui/generic-table";
import PropTypes from "prop-types";

function totalRenderer(rowData) {
  return `$${rowData.price} MXN`;
}

function ConceptsSelectorDialog(props) {
  const { open, doBackdropClick, doClose, doCancel, selectionHandler } = props;

  const fetchData = async (query) => {
    try {
      const token = getSessionToken();
      const clients = await getAllConcept(token, query);
      return {
        data: clients.data,
        page: clients.current_page - 1,
        totalCount: clients.total,
      };
    } catch {
      console.log("Error al obtener los conceptos");
    }
  };
  const columns = [
    { title: "Nombre", field: "name" },
    { title: "Descripción", field: "description", type: "numeric" },
    { title: "Precio", field: "price", type: "numeric", render: totalRenderer },
  ];

  const actions = [
    {
      icon: "done",
      tooltip: "Seleccionar",
      onClick: (action, row) => {
        if (selectionHandler) {
          selectionHandler(row);
        }
      },
    },
  ];

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={doClose}
      onBackdropClick={doBackdropClick}
    >
      <DialogTitle>
        {"Seleccione un concepto para añadir a la orden de pago"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Seleccione en el siguiente listado un concepto para añadir la orden de
          pago:
        </DialogContentText>
        <GenericTable
          columns={columns}
          fetch={fetchData}
          actions={actions}
          pageSizeOptions={[5]}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={doCancel} color="inherit">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConceptsSelectorDialog.propTypes = {
  open: PropTypes.bool,
  doBackdropClick: PropTypes.func,
  doClose: PropTypes.func,
  doCancel: PropTypes.func,
  selectionHandler: PropTypes.func,
};

export { ConceptsSelectorDialog };

import * as authActionTyoes from "../actions/auth-action-types";
import { getSessionToken } from "../actions/auth-actions";

const initialState = {
  session: !!getSessionToken(),
  me: null,
};

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case authActionTyoes.LOG_IN_SUCCESS:
      return { ...state, session: !!getSessionToken() };
    case authActionTyoes.LOG_OUT:
      return { ...state, session: false };
    case authActionTyoes.SET_ME:
      return { ...state,  me: payload};
    case authActionTyoes.REMOVE_ME:
      return { ...state, me: null };
    default:
      return state;
  }
}

import { Button, IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { loginUser } from "../../actions/auth-actions";
import { apiErrorHandler } from "../../api/api-error-handler";
import styles from "./login-styles";

function Copyright() {
  return (
    <React.Fragment>
      <Typography variant="body2" color="textSecondary" align="center">
        {" © "} {new Date().getFullYear()}{" "}
        <Link color="inherit" href="#">
          Universidad Incarnate Word Campus Bajío
        </Link>
        {"."}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        Todos los derechos reservados{"."}
      </Typography>
    </React.Fragment>
  );
}

function Login(props) {
  const { classes } = props;

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  const validation = Yup.object({
    email: Yup.string()
      .email("Correo electrónico inválido.")
      .required("Campo requerido"),
    password: Yup.string()
      .required("Campo requerido")
      // .min(8, "La constraseña debe tener un mínimo de 8 caracteres.")
      .matches(
        /[a-zA-Z0-9]+/,
        "La constraseña solo puede tener caracteres alfanuméricos."
      ),
  });

  const submitHandler = async (values, helpers) => {
    const { setSubmitting } = helpers;
    try {
      await dispatch(loginUser(values));
      await props.history.push("/");
    } catch (error) {
      apiErrorHandler(error, helpers);
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.containerLogin}>
      <Container
        component="main"
        maxWidth="xs"
        className={classes.bgcolorwhite}
      >
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validation}
            onSubmit={submitHandler}
          >
            {({ isSubmitting, status }) => {
              return (
                <Form>
                  {status && (
                    <Box mt={4} mb={2}>
                      <Alert variant="outlined" severity="error">
                        {status}
                      </Alert>
                    </Box>
                  )}

                  <Field
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Correo Electrónico"
                    name="email"
                    type="email"
                    autoComplete="off"
                    autoFocus
                  />
                  <Field
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Contraseña"
                    name="password"
                    type={values.showPassword ? "text" : "password"}
                    autoComplete="off"
                    InputProps={{
                      classes: { input: classes.input },
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                  >
                    Entrar
                  </Button>

                  <Button
                    fullWidth
                    variant="text"
                    color="inherit"
                    disabled={isSubmitting}
                    onClick={() => props.history.push("/")}
                  >
                    Volver
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(Login);

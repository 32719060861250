import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Link,
  List,
  ListItem,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { logOutClient } from "../../actions/my-payments-actions";
import { getMyPayments } from "../../api/my-payments-api";
import { FormUserPublicData } from "../forms/form-user-data-public";
import { withBasicLayout } from "../layout/basic-layout";
import { GenericTable } from "../ui/generic-table";
import styles from "./my-payments-styles";
import MyPaymentsAddFromCode from "./my-payments-addfromcode";

function renderer(rowData) {
  return `$${rowData.total} MXN`;
}

function MyPayments(props) {
  const { classes } = props;

  // Component history
  const history = useHistory();

  // Redux dispatchet
  const dispatch = useDispatch();

  // Client identifier
  const clientId = useSelector((state) => state.myPayments.id);

  const handleSession = () => {
    dispatch(logOutClient());
    window.location = "/";
  };

  const columns = [
    { title: "Fecha", field: "created_at", type: "datetime", defaultSort: "desc" },
    { title: "Nombre", field: "description" },
    // { title: "Total", field: "total", type: "numeric", render: renderer},
    {
      title: "Estado",
      field: "status",
      lookup: {
        pending: "Pendiente",
        processing: "En proceso",
        completed: "Pagada",
        canceled: "Cancelada",
        failed: "Error",
      },
    },
  ];

  const fetchData = async (query) => {
    try {
      const response = await getMyPayments({ ...query, id: clientId });
      const orders = response.data;
      return {
        data: orders.data,
        page: orders.current_page - 1,
        totalCount: orders.total,
      };
    } catch {
      console.log("Error al obtener las ordenes de pago.");
    }
  };

  const onClickHandler = (_, row) => {
    history.push(`/my-payments/${row.id}/details`);
  };

  return (
    <Fragment>
      {!clientId && <Redirect to="/" />}
      <Container maxWidth="lg">
        <Box className={classes.topData}>
          <Box className={classes.topDataLeft}>
            <Card variant="elevation">
              <CardHeader
                title="Mis datos"
                subheader="Datos del solicitante"
                className={classes.policies}
              />
              <CardContent>
                <FormUserPublicData />
                <Box
                  mt={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSession()}
                  >
                    Salir
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box className={classes.topDataRight}>
            <Card variant="elevation">
              <CardMedia
                className={classes.policiesMedia}
                image="/img/bg-college.jpg"
                title="Contemplative Reptile"
              />
              <CardHeader
                title="Importante"
                subheader="Políticas de servicio y privacidad."
                className={classes.policies}
              />
              <CardContent className={classes.policiesContent}>
                <List dense>
                  <ListItem>
                    <Link target="_blank" href="#">
                      Politicas de reembolso o cancelación
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://uiwbajio.mx/reglamento-general-licenciaturas"
                    >
                      Reglamento general de Licenciaturas
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link target="_blank" href="https://uiwbajio.mx/privacidad">
                      Aviso de privacidad
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="mailto:pagoenlinea@uiwbajio.mx">
                      Reportar un problema
                    </Link>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Box>
        </Box>

        <Box className={classes.topData}>
          <Card variant="elevation" className={classes.addFromCodeBox}>
            <CardHeader
              title="Añadir orden desde código"
              subheader="Añade una nueva orden desde un código pre-generado."
              className={classes.policies}
            />
            <CardContent>
              <MyPaymentsAddFromCode />
            </CardContent>
          </Card>
        </Box>

        <Box className={classes.table}>
          <GenericTable
            columns={columns}
            fetch={fetchData}
            onClickHandler={onClickHandler}
          />
        </Box>
      </Container>
    </Fragment>
  );
}

MyPayments.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withBasicLayout(withStyles(styles)(MyPayments));

import { createBrowserHistory } from "history";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import Clients from "../pages/admin-clients";
import ClientsForm from "../pages/admin-clients-form";
import Concepts from "../pages/admin-concepts";
import ConceptsForm from "../pages/admin-concepts-form";
import { ConfigurationForm } from '../pages/admin-configuration-form';
import Orders from "../pages/admin-orders";
import OrdersForm from "../pages/admin-orders-form/admin-orders-form";
import Reports from "../pages/admin-reports";
import { TemplatesForm } from '../pages/admin-templates-form';
import { TemplatesList } from '../pages/admin-templates';
import Home from "../pages/home";
import Login from "../pages/login";
import MyPayments from "../pages/my-payments";
import PaymentDetails from "../pages/payment-details";
import PaymentsRoute from "./payments-route";
import PrivateRoute from "./private-route";
import PublicRoute from "./public-route";
import ImportFormComponent from '../pages/import/import-form';
import Register from '../pages/register';
import RegisterSeminarioTurismoMedico from '../pages/registerserminarioturismomedico';

export const history = createBrowserHistory();

export default function RouterWrapper() {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute path="/admin/import" component={ImportFormComponent} />
        <PrivateRoute
          path="/admin/concepts/add"
          component={ConceptsForm}
          exact
        />
        <PrivateRoute
          path="/admin/concepts/:conceptid/edit"
          component={ConceptsForm}
          exact
        />
        <PrivateRoute path="/admin/concepts" component={Concepts} />

        <PrivateRoute path="/admin/orders/add" component={OrdersForm} exact />
        <PrivateRoute
          path="/admin/orders/:orderid/edit"
          component={OrdersForm}
          exact
        />
        <PrivateRoute path="/admin/orders" component={Orders} />

        <PrivateRoute path="/admin/templates/add" component={TemplatesForm} exact />
        <PrivateRoute
          path="/admin/templates/:templateid/edit"
          component={TemplatesForm}
          exact
        />
        <PrivateRoute path="/admin/templates" component={TemplatesList} />

        <PrivateRoute path="/admin/clients/add" component={ClientsForm} exact />
        <PrivateRoute
          path="/admin/clients/:clientid/edit"
          component={ClientsForm}
          exact
        />
        <PrivateRoute path="/admin/clients" component={Clients} />
        <PrivateRoute path="/admin/reports" component={Reports} />
        <PrivateRoute path="/admin/config" component={ConfigurationForm} />

        <PaymentsRoute
          path="/my-payments/:paymentid/details"
          component={PaymentDetails}
        />
        <PaymentsRoute
          path="/my-payments/:paymentid/confirm"
          component={PaymentDetails}
        />
        <PaymentsRoute path="/my-payments" component={MyPayments} />

        <PublicRoute path="/register" component={Register} />
        <PublicRoute path="/seminario-turismo-medico-2021" component={RegisterSeminarioTurismoMedico} />
        <PublicRoute path="/login" component={Login} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

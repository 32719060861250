import { CssBaseline, ThemeProvider } from "@material-ui/core";
import React from "react";
import { Provider, useDispatch } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hasSessionToken, updateMe } from '../actions/auth-actions';
import configureStore from "../store/configure-store";
import theme from "../theme/create-theme";
import Router from "./router/router";

function App() {
  const dispatch = useDispatch();

  if (hasSessionToken()) {
    dispatch(updateMe());
  }

  return <Router />;
}

function ThemedApp() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
      <ToastContainer position="bottom-right" />
    </ThemeProvider>
  );
}

function ReduxWrapApp() {
  const store = configureStore();

  return (
    <Provider store={store}>
      <ThemedApp />
    </Provider>
  );
}

export default ReduxWrapApp;

import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import adminReducer from "../reducers/admin-reducer";
import authReducer from "../reducers/auth-reducer";
import myPaymentsReducer from "../reducers/my-payments-reducer";

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface AppState {
  auth: {
      me: any;
      session: boolean;
  };
  admin: {
      showMenu: boolean;
  };
  myPayments: {
      id: string | null;
  };
}

const appCreateStore = () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      admin: adminReducer,
      myPayments: myPaymentsReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};

export default appCreateStore;

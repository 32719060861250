import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
  withStyles
} from "@material-ui/core";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getMyPaymentsConcepts } from "../../api/my-payments-api";
import styles from "./form-user-payments-public-styles";

function FormUserPaymentsPublicComponent(props) {
  const { classes } = props;
  const { paymentid } = useParams();
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const clientid = useSelector((state) => state.myPayments.id);

  const getInitialValues = async () => {
    try {
      const response = await getMyPaymentsConcepts(clientid, paymentid);

      setInitialValues({ concepts: response.data });
      setShowForm(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (!initialValues) {
    getInitialValues();
  }

  return (
    <React.Fragment>
      {!showForm && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {showForm && (
        <Formik
          initialValues={initialValues}
          validationSchema={{}}
          onSubmit={() => {}}
        >
          {({ values }) => (
            <Form>
              <Box mb={4}>
                <FieldArray
                  name="concepts"
                  render={() => {
                    return (
                      <React.Fragment>
                        <Grid item xs={12} mb={4}>
                          {(!values ||
                            !values.concepts ||
                            values.concepts.length === 0) && (
                            <Box textAlign="center" p={2}>
                              <Typography
                                variant="subtitle1"
                                component="span"
                                color="textSecondary"
                              >
                                No se han añadido conceptos
                              </Typography>
                            </Box>
                          )}
                          {values &&
                            values.concepts.length > 0 &&
                            values.concepts.map((concept, i) => (
                              <Grid
                                container
                                spacing={3}
                                key={i}
                                className={classes.conceptItem}
                              >
                                <Grid item xs={12} sm={12}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    type="text"
                                    name={`concepts.${i}.name`}
                                    label="Nombre del concepto"
                                    variant="standard"
                                    disabled
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    type="text"
                                    name={`concepts.${i}.quantity`}
                                    label="Cantidad"
                                    disabled
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    type="text"
                                    name={`concepts.${i}.subtotal`}
                                    label="Subtotal"
                                    disabled
                                    required
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    type="text"
                                    name={`concepts.${i}.taxes`}
                                    label="Impuestos"
                                    disabled
                                    required
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      ),
                                    }}
                                    variant="standard"
                                  />
                                </Grid>

                                <Grid item xs={4} sm={3}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    type="text"
                                    name={`concepts.${i}.total`}
                                    label="Total"
                                    disabled
                                    required
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    variant="standard"
                                  />
                                </Grid>

                                <Grid item xs={8} sm={8}>
                                  <Typography
                                    variant="subtitle2"
                                    component="span"
                                    color="textSecondary"
                                  >
                                    {concept.description}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </React.Fragment>
                    );
                  }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
}

const FormUserPaymentsPublic = withStyles(styles)(
  FormUserPaymentsPublicComponent
);

export default FormUserPaymentsPublic;

export { FormUserPaymentsPublic };

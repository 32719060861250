import * as actionTypes from '../actions/admin-actions-types';

const initialState = {
  showMenu: false,
}

export default function (state = initialState, {type}) {
  switch (type) {
    case actionTypes.TOGGLE_ADMIN_MENU:
      return { ...state, showMenu: !state.showMenu};
    case actionTypes.HIDE_ADMIN_MENU:
      return { ...state, showMenu: false};
    case actionTypes.SHOW_ADMIN_MENU:
      return { ...state, showMenu: true};
    default:
      return state;
  }
}

// @ts-check

/* eslint-disable no-unused-vars */
import MaterialTable, { Localization } from "material-table";
import PropTypes from "prop-types";
import React from "react";
import { Paper } from "@material-ui/core";

/** @type {Localization} Localization configuration. */
const localization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsSelect: "resultados",
    firstTooltip: "Primera página",
    lastTooltip: "Última página",
    previousTooltip: "Página anterior",
    nextTooltip: "Página siguiente",
    labelRowsPerPage: "Resultados por página:",
  },
  toolbar: {
    nRowsSelected: "{0} columna(s) seleccionadas",
    searchPlaceholder: "Buscar",
    searchTooltip: "Buscar",
  },
  header: {
    actions: "Acciones",
  },
  body: {
    emptyDataSourceMessage: "No hay resultados para mostrar",
    filterRow: {
      filterTooltip: "Filtros",
    },
  },
};

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  getTableRef() {
    return this.tableRef;
  }

  render() {
    const {
      fetch,
      columns,
      onClickHandler,
      actions,
      pageSizeOptions,
      defaultGroupOrder
    } = this.props;

    let tableActions = [
      {
        icon: "find_in_page",
        tooltip: "Ver",
        onClick: (action, row) => {
          onClickHandler(action, row);
        },
      },
    ];

    if (actions) {
      tableActions = actions;
    }

    return (
      <MaterialTable
        title={null}
        columns={columns}
        data={fetch}
        options={{
          actionsColumnIndex: -1,
          debounceInterval: 800,
          pageSizeOptions,
          pageSize: pageSizeOptions[0]
        }}
        actions={tableActions}
        localization={localization}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        tableRef={this.tableRef}
      />
    );
  }
}

Table.propTypes = {
  fetch: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  onClickHandler: PropTypes.func,
  actions: PropTypes.array,
  pageSizeOptions: PropTypes.array,
  defaultGroupOrder: PropTypes.number
};

Table.defaultProps = {
  pageSizeOptions: [10, 20, 50, 100, 200],
  defaultGroupOrder: 1
};

export default Table;

export { Table as GenericTable };

import { create } from "./axios-api.factory";

export async function myPaymentsSession(data) {
  const axios = create();

  return await axios.post(`/api/my-payments/session`, data);
}

export async function getMyPaymentsClientInfo(id) {
  const axios = create();

  return await axios.get(`/api/my-payments/get-info`, { params: { id } });
}

export async function getMyPayments(data) {
  const axios = create();

  return await axios.get(`/api/my-payments/all`, { params: { ...data } });
}

export async function getMyPaymentsConcepts(clientid, orderid) {
  const axios = create();

  return await axios.get(`/api/my-payments/get-concepts`, {
    params: { clientid, orderid },
  });
}

export async function getPaymentConfiguration() {
  const axios = create();

  return await axios.get(`/api/my-payments/configuration`);
}

export async function getPaymentState(clientid, orderid) {
  const axios = create();

  return await axios.get(`/api/my-payments/get-payment-state`, {
    params: { clientid, orderid },
  });
}

export async function requestPayment(clientid, orderid, returnUrl) {
  const axios = create();

  return await axios.post(`/api/my-payments/request-payment`, { clientid, orderid, returnUrl });
}

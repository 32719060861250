import { Box, Button, Card, CardContent, CardHeader, Container, Grid, Hidden, Paper } from '@material-ui/core';
import { StyledComponentProps, withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import ImageConcept from "../../../assets/img/concepts-form-img.JPG";
import { withBasicLayout } from '../../layout/basic-layout';
import styles from './styles';
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import { Field, Form, Formik, FormikHelpers, FormikValues } from "formik";
import CancelConfirmDialog from "../../ui/cancel-confirm-dialog";
import { getSessionToken } from "../../../actions/auth-actions";
import { apiErrorHandler } from "../../../api/api-error-handler";
import { TextField } from 'formik-material-ui';
import { postRegisterAuto } from '../../../api/auto-register';
import { useDispatch, useSelector } from "react-redux";
import { clientLoginAction, loginClient, setClientToken } from '../../../actions/my-payments-actions';
import { AxiosError } from 'axios';

interface Props extends StyledComponentProps {
}

function RegisterPage(props: Props) {
  const { classes } = props;

  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState<FormikValues | null>(null);
  const [initialCode, setInitialCode] = useState(false)

  const dispatch = useDispatch();

  const back = () => history.push("/admin/clients");

  const validation = Yup.object({
    name: Yup.string()
      .min(2, "El valor ingresado es demasiado corto.")
      .required("Campo requerido"),
    first_lastname: Yup.string()
      .min(2, "El valor ingresado es demasiado corto.")
      .required("Campo requerido"),
    second_lastname: Yup.string().min(
      2,
      "El valor ingresado es demasiado corto."
    ),
    email: Yup.string()
      .email("Correo electrónico inválido.")
      .required("Campo requerido"),
    phone: Yup.string()
      .matches(/^\d{10}$/, 'El valor introducido debe ser solo numérico, sin simbolos especiales.')
      .min(10, "El valor debe consistir de 10 caracteres.")
      .max(10, "El valor debe consistir de 10 caracteres."),
    code: Yup.string()
      .matches(
        /[a-zA-Z0-9]+/,
        "La constraseña solo puede tener caracteres alfanuméricos (a-z, A-Z, 0-9).")
      .max(128, "La longitud máxima es de 128 caracteres alfanuméricos.")
      .required("Campo requerido"),
  });

  if (!initialValues) {
    const params = getQueryParams();
    const code = params.get("code");

    if (code) {
      setInitialCode(true);
    }

    setInitialValues({
      identifier: "",
      name: "",
      first_lastname: "",
      second_lastname: "",
      email: "",
      phone: "",
      code: code ?? ""
    });

    setShowForm(true);
  }

  const submitHandler = async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
    const { setSubmitting } = helpers;
    try {
      const response = await postRegisterAuto(values);
      const data = response.data;

      const client = data.client;
      const order = data.order;

      setClientToken(client.id);
      await dispatch(clientLoginAction());

      history.push(`/my-payments/${order.id}/details`);
    } catch (error) {
      apiErrorHandler(error as AxiosError<any>, helpers);
      setSubmitting(false);
    }
  };

  return <>
    <Box className={classes?.root}>
      <Container>
        <Box mb={3}>
          <Card variant="elevation">
            <CardHeader
              title="Registro"
              subheader="Introduce tu información para registrarte y realizar tus pagos."
              className={classes?.policies}
            />
            <CardContent className={classes?.fieldsFlex}>
              <Grid container spacing={3}>
                <Grid item md={8}>
                  {showForm && <Formik
                    initialValues={initialValues as FormikValues}
                    validationSchema={validation}
                    onSubmit={submitHandler}
                  >
                    {({ isSubmitting, status }) => {
                      return (
                        <Form>
                          {status && (
                            <Box mt={4} mb={2}>
                              <Alert variant="outlined" severity="error">
                                {status}
                              </Alert>
                            </Box>
                          )}
                          <Box mb={4}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  autoComplete="off"
                                  InputLabelProps={{ shrink: true }}
                                  type="email"
                                  name="email"
                                  label="Correo electrónico"
                                  helperText="Correo electrónico del solicitante"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  type="text"
                                  InputLabelProps={{ shrink: true }}
                                  name="name"
                                  label="Nombre"
                                  helperText="Nombre solicitante"
                                  required
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  type="text"
                                  InputLabelProps={{ shrink: true }}
                                  name="first_lastname"
                                  label="Apellido paterno"
                                  helperText="Apellido paterno del solicitante"
                                  required
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  type="text"
                                  autoComplete="off"
                                  InputLabelProps={{ shrink: true }}
                                  name="second_lastname"
                                  label="Apellido materno"
                                  helperText="Apellido materno del solicitante"
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  autoComplete="off"
                                  InputLabelProps={{ shrink: true }}
                                  type="phone"
                                  name="phone"
                                  label="Teléfono"
                                  helperText="Teléfono del solicitante"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  type="text"
                                  name={`code`}
                                  label="Código identificador"
                                  helperText="Código identificador del pago a realizar. Ingrese el código del concepto o evento a registrar."
                                  variant="standard"
                                  disabled={initialCode}
                                  required
                                />
                              </Grid>
                            </Grid>
                          </Box>

                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="inherit"
                                className={classes?.submit}
                                disabled={isSubmitting}
                                onClick={() => setShowDialog(true)}
                              >
                                Volver
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes?.submit}
                                disabled={isSubmitting}
                              >
                                Registrar
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>}
                </Grid>
                <Hidden smDown>
                  <Grid item md={4}>
                    <div className={classes?.boxImageConcept}>
                      <img
                        className={classes?.imageConcept}
                        src={ImageConcept}
                        alt="Registro"
                      />
                    </div>
                  </Grid>
                </Hidden>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
    <CancelConfirmDialog
      hide={() => setShowDialog(false)}
      handleAccept={() => {
        back();
      }}
      open={showDialog}
    />
  </>;
}

export default withBasicLayout(withStyles(styles as any)(RegisterPage));


function getQueryParams(): URLSearchParams {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params;
}

import * as types from "./my-payments-action-types";
import { myPaymentsSession } from '../api/my-payments-api';

const CLIENT_TOKEN = "JWTCLIENT";

export function clientLoginAction() {
  return { type: types.CLIENT_LOG_IN_SUCCESS };
}

export function clientLogoutAction() {
  return { type: types.CLIENT_LOG_OUT };
}

export function loginClient(data) {
  return async function (dispatch) {
    const response = await myPaymentsSession(data);

    setClientToken(response.data.id);
    await dispatch(clientLoginAction());
  };
}

export function logOutClient() {
  return async function (dispatch) {
    removeClientToken();
    dispatch(clientLogoutAction());
  };
}

export function removeClientToken() {
  sessionStorage.removeItem(CLIENT_TOKEN);
}

export function getClientToken() {
  return sessionStorage.getItem(CLIENT_TOKEN);
}

export function setClientToken(token) {
  sessionStorage.setItem(CLIENT_TOKEN, token);
}

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  withStyles,
  Paper,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { getSessionToken } from "../../actions/auth-actions";
import { getAllClient } from "../../api/clients-api";
import { withBasicLayout } from "../layout/basic-layout";
import GenericTable from "../ui/generic-table";
import styles from "./admin-clients-styles";

function Clients(props) {
  const { classes } = props;

  const history = useHistory();

  const fetchData = async (query) => {
    try {
      const token = getSessionToken();
      const clients = await getAllClient(token, query);
      return {
        data: clients.data,
        page: clients.current_page - 1,
        totalCount: clients.total,
      };
    } catch {
      console.log("Error al obtener los clientes");
    }
  };

  const columns = [
    { title: "Fecha de creación", field: "created_at", type: "datetime", defaultSort: "desc" },
    { title: "Identificador", field: "identifier" },
    { title: "Nombre", field: "fullname" },
    { title: "Correo electrónico", field: "email" },
  ];

  const onClickHandler = (action, row) => {
    history.push(`/admin/clients/${row.id}/edit`);
  };

  return (
    <Box className={classes.root}>
      <Container>
        <Box mb={3}>
          <Card variant="elevation">
            <CardHeader
              title="Clientes"
              subheader="
                Este listado muestra todos los clientes disponibles
                para generar ordenes de pago."
              className={classes.policies}
            />
            <CardContent className={classes.fieldsFlex}>
              <Button
                variant="text"
                color="secondary"
                className={classes.button}
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => history.push("/admin/clients/add")}
              >
                Añadir cliente
              </Button>
            </CardContent>
          </Card>
        </Box>
        <Box className={classes.table}>
          <Paper elevation={2}>
            <GenericTable
              onClickHandler={onClickHandler}
              columns={columns}
              fetch={fetchData}
            />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}

Clients.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withBasicLayout(withStyles(styles)(Clients));

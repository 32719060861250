import { Box, Grid } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React from "react";
import * as Yup from "yup";

function FormUserData(props) {
  const { showAllFields, showFullname, disabled } = props;

  return (
    <Box mb={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            fullWidth
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            type="text"
            name="identifier"
            label="Matricula"
            helperText="Correo electrónico o mátricula del solicitante"
            required
            disabled={disabled}
          />
        </Grid>

        {showAllFields && (
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              fullWidth
              type="text"
              InputLabelProps={{ shrink: true }}
              name="name"
              label="Nombre"
              helperText="Nombre solicitante"
              required
              disabled={disabled}
            />
          </Grid>
        )}

        {showAllFields && (
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              fullWidth
              type="text"
              InputLabelProps={{ shrink: true }}
              name="first_lastname"
              label="Apellido paterno"
              helperText="Apellido paterno del solicitante"
              required
              disabled={disabled}
            />
          </Grid>
        )}

        {showAllFields && (
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              fullWidth
              type="text"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              name="second_lastname"
              label="Apellido materno"
              helperText="Apellido materno del solicitante"
              disabled={disabled}
            />
          </Grid>
        )}

        {showFullname && (
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              fullWidth
              type="text"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              name="fullname"
              label="Nombre"
              helperText="Nombre del solicitante"
              disabled={disabled}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            fullWidth
            type="text"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            name="academicprogram"
            label="Programa de estudio"
            helperText="Programa de estudio del solicitante"
            variant="standard"
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            fullWidth
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            type="email"
            name="email"
            label="Correo electrónico"
            helperText="Correo electrónico del solicitante"
            required
            disabled={disabled}
          />
        </Grid>

        {showAllFields && (
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              fullWidth
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              type="phone"
              name="phone"
              label="Teléfono"
              helperText="Teléfono del solicitante"
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

FormUserData.propTypes = {
  showAllFields: PropTypes.bool,
  showFullname: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormUserData.defaultProps = {
  showAllFields: false,
  showFullname: false,
  disabled: false,
};

export default FormUserData;

export const FormUserDataValidation = {
  identifier: Yup.string()
    .matches(/[a-zA-Z0-9\-\@\.\_]+/, 'El valor solo puede ser alfanumerico o incluir los siguientes caracteres: .-_@')
    .required("Campo requerido"),
  name: Yup.string()
    .min(2, "El valor ingresado es demasiado corto.")
    .required("Campo requerido"),
  first_lastname: Yup.string()
    .min(2, "El valor ingresado es demasiado corto.")
    .required("Campo requerido"),
  second_lastname: Yup.string().min(
    2,
    "El valor ingresado es demasiado corto."
  ),
  email: Yup.string()
    .email("Correo electrónico inválido.")
    .required("Campo requerido"),
  phone: Yup.string()
    .matches(/^\d{10}$/, 'El valor introducido debe ser solo numérico, sin simbolos especiales.')
    .min(10, "El valor debe consistir de 10 caracteres.")
    .max(10, "El valor debe consistir de 10 caracteres."),
  academicprogram: Yup.string()
    .min(2, "El valor ingresado es demasiado corto."),
};

import { red, grey } from '@material-ui/core/colors';

const styles = (theme) => ({
  conceptItem: {
    borderBottom: `2px solid ${red[900]}`,
    background: grey[50],
    padding: theme.spacing(1, 2),
  }
});

export default styles;

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  withStyles
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { loginClient } from "../../actions/my-payments-actions";
import { apiErrorHandler } from "../../api/api-error-handler";
import logo from "../../assets/img/logo_horizontal_negro.png";
import { isDev } from '../../utils/environment';
import { withBasicLayout } from "../layout/basic-layout";
import styles from "./home-styles";

function Home(props) {
  const { classes } = props;

  // Router history.
  const history = useHistory();

  // Redux dispatcher.
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.myPayments.id);

  // Form initial values.
  const [initialValues, setInitialValues] = useState(null);

  if (!initialValues) {
    setInitialValues({
      identifier: "",
      recaptcha: null,
    });
  }

  // Form validation object.
  const validation = Yup.object({
    identifier: Yup.string().required("Campo requerido"),
    ...(!isDev()) && {
      recaptcha: Yup.string()
        .typeError("La validación de usuario es requerida")
        .required("La validación de usuario es requerida")
    },
  });

  // Form submit handler.
  const submitHandler = async (values, helpers) => {
    const { setSubmitting } = helpers;
    try {
      await dispatch(loginClient(values));

      history.push("/my-payments");
    } catch (error) {
      apiErrorHandler(error, helpers);
      setSubmitting(false);
    }
  };

  return (
    <Box className={classes.root}>
      {clientId && <Redirect to="/my-payments" />}
      <Container maxWidth="md">
        <Box>
          <Box className={classes.instructions}>
            <img
              src={logo}
              alt="UIW Campus Bajío"
              className={classes.logoHome}
            />
          </Box>

          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={submitHandler}
          >
            {({ isSubmitting, status, setFieldValue }) => {
              return (
                <Form>
                  {status && (
                    <Box mt={4} mb={2}>
                      <Alert variant="outlined" severity="error">
                        {status}
                      </Alert>
                    </Box>
                  )}

                  <Grid container>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        fullWidth
                        name="identifier"
                        label="Matricula universitaria o correo electrónico"
                        autoComplete="off"
                        type="text"
                        helperText="Ingresa tu código de matricula o correo electrónico y presiona
                          el botón buscar. Si existe una orden de pago pendiente asociada
                          a tu código te mostraremos un listado para que realices tu pago."
                        variant="outlined"
                        disabled={isSubmitting}
                        required
                      />
                    </Grid>
                  </Grid>

                  <Box
                    display="flex"
                    justifyContent="center"
                    className={classes.captchaContainer}
                  >
                    {!isDev() &&
                      <ReCAPTCHA
                        hl="es-419"
                        sitekey="6Le5n_oUAAAAAK7fR_1uj_iXTjSEQO2lBKTlyufd"
                        onChange={(token) => {
                          setFieldValue("recaptcha", token);
                        }}
                      />
                    }
                  </Box>

                  <Box display="flex" justifyContent="center">
                    <ErrorMessage
                      name="recaptcha"
                      component={Alert}
                      severity="error"
                    />
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className={classes.submitBtn}
                        variant="contained"
                        color="primary"
                      >
                        {isSubmitting && (
                          <CircularProgress color="inherit" size={24} />
                        )}
                        {!isSubmitting && "Buscar"}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className={classes.submitBtn}
                        variant="contained"
                        color="default"
                        onClick={() => history.push("/register")}
                      >
                        Registro
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withBasicLayout(withStyles(styles)(Home));

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  withStyles,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { getSessionToken } from "../../actions/auth-actions";
import { apiErrorHandler } from "../../api/api-error-handler";
import { getClient, postClient, updateClient } from "../../api/clients-api";
import ImageConcept from "../../assets/img/concepts-form-img.JPG";
import FormUserData, { FormUserDataValidation } from "../forms/form-user-data";
import { withBasicLayout } from "../layout/basic-layout";
import CancelConfirmDialog from "../ui/cancel-confirm-dialog";
import styles from "./admin-clients-form-styles";

const FORM_TYPE_ADD = 0;
const FORM_TYPE_EDIT = 1;

function ClientsForms(props) {
  const { classes, match } = props;

  const history = useHistory();

  const [showDialog, setShowDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  // Get form type.
  const location = useLocation();
  const FORM_TYPE = location.pathname.endsWith("/add")
    ? FORM_TYPE_ADD
    : FORM_TYPE_EDIT;

  const back = () => history.push("/admin/clients");

  const validation = Yup.object({
    ...FormUserDataValidation,
  });

  const submitHandler = async (values, helpers) => {
    const { setSubmitting } = helpers;
    try {
      if (FORM_TYPE_ADD === FORM_TYPE) {
        const token = getSessionToken();
        await postClient(token, values);
        await props.history.push("/admin/clients");
      } else {
        const token = getSessionToken();
        await updateClient(token, values);
        await props.history.push("/admin/clients");
      }
    } catch (error) {
      apiErrorHandler(error, helpers);
      setSubmitting(false);
    }
  };

  const getInitialValues = async (id) => {
    try {
      const token = getSessionToken();
      const response = await getClient(token, id);

      setInitialValues(response.data);
      setShowForm(true);
    } catch (error) {

      // TODO: Añadir handler para manejo de este tipo de errores.
      console.log(error);
    }
  };

  if (FORM_TYPE_ADD === FORM_TYPE && !initialValues) {
    setInitialValues({
      identifier: "",
      name: "",
      first_lastname: "",
      second_lastname: "",
      email: "",
      phone: "",
      academicprogram: "",
    });

    if (!showForm) {
      setShowForm(true);
    }
  }

  if (FORM_TYPE_EDIT === FORM_TYPE && !initialValues) {
    getInitialValues(match.params.clientid);
  }

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card className={classes.root}>
                <CardHeader
                  title={
                    FORM_TYPE === FORM_TYPE_ADD
                      ? "Añadir cliente"
                      : "Editar cliente"
                  }
                  subheader="Administre sus cuentas de cliente"
                />
                <CardContent>
                  {!showForm && (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  )}
                  {showForm && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validation}
                      onSubmit={submitHandler}
                    >
                      {({ isSubmitting, status }) => {
                        return (
                          <Form>
                            {status && (
                              <Box mt={4} mb={2}>
                                <Alert variant="outlined" severity="error">
                                  {status}
                                </Alert>
                              </Box>
                            )}

                            <FormUserData showAllFields />
                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <Button
                                  type="button"
                                  fullWidth
                                  variant="contained"
                                  color="inherit"
                                  className={classes.submit}
                                  disabled={isSubmitting}
                                  onClick={() => setShowDialog(true)}
                                >
                                  Cancelar
                                </Button>
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  disabled={isSubmitting}
                                >
                                  Guardar
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Hidden smDown>
              <Grid item md={4}>
                <div className={classes.boxImageConcept}>
                  <img
                    className={classes.imageConcept}
                    src={ImageConcept}
                    alt="Clientes"
                  />
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <CancelConfirmDialog
        hide={() => setShowDialog(false)}
        handleAccept={() => {
          back();
        }}
        open={showDialog}
      />
    </React.Fragment>
  );
}

ClientsForms.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  match: PropTypes.object.isRequired,
};

export default withBasicLayout(withStyles(styles)(ClientsForms));

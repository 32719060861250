import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  withStyles,
  Typography,
  InputAdornment,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Field, Form, Formik, FieldArray, ErrorMessage } from "formik";
import { Select, TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { getSessionToken } from "../../../actions/auth-actions";
import { apiErrorHandler } from "../../../api/api-error-handler";
import { getClient } from "../../../api/clients-api";
import ImageConcept from "../../../assets/img/concepts-form-img.JPG";
import FormUserData, {
  FormUserDataValidation,
} from "../../forms/form-user-data";
import { withBasicLayout } from "../../layout/basic-layout";
import CancelConfirmDialog from "../../ui/cancel-confirm-dialog";
import styles from "./admin-orders-form-styles";
import { ClientSelectorDialog } from "./client-selector-dialog";
import { ConceptsSelectorDialog } from "./concept-selector-dialog";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import {
  postOrder,
  getOrder,
  deleteOrderItem,
  updateOrder,
} from "../../../api/orders-api";
import { format, parseISO } from 'date-fns'

const FORM_TYPE_ADD = 0;
const FORM_TYPE_EDIT = 1;

const FORM_USER_INFO_LOADING = 0;
const FORM_USER_INFO_DONE = 1;

function OrdersForm(props) {
  const { classes, match } = props;

  // Form history
  const history = useHistory();

  // Dialog states
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showClientDialog, setClientSelectorDialog] = useState(false);
  const [showConceptDialog, setConceptSelectorDialog] = useState(false);

  const [penalizations, setPenalizations] = useState(0);

  // Used when loading the form.
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  // Used for client selector handlers.
  const [showClientInfo, setShowClientInfo] = useState(null);

  // Get form type.
  const location = useLocation();
  const FORM_TYPE = location.pathname.endsWith("/add")
    ? FORM_TYPE_ADD
    : FORM_TYPE_EDIT;

  // Back navigation handler.
  const back = () => history.push("/admin/orders");

  // Form validation YUP object.
  const validation = Yup.object({
    ...FormUserDataValidation,
    ...getPenalizationValidations(penalizations),
    status: Yup.string()
      .required("Debe seleccionar un estado"),
    clientid: Yup.string()
      .required("Debe seleccionar un cliente.")
      .matches(
        /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}){1}/,
        "Debe seleccionar un cliente."
      ),
    concepts: Yup.array().min(1, "Debe seleccionar por lo menos un concepto."),
  });

  // Submit handler
  const submitHandler = async (values, helpers) => {
    const { setSubmitting } = helpers;
    try {
      const token = getSessionToken();
      if (FORM_TYPE_ADD === FORM_TYPE) {
        await postOrder(token, values);
        back();
      } else {
        await updateOrder(token, values);
        back();
      }
    } catch (error) {
      apiErrorHandler(error, helpers);
      setSubmitting(false);
    }
  };

  // Initial values retrieve handler.
  const getInitialValues = async (id) => {
    try {
      const token = getSessionToken();
      const response = await getOrder(token, id);

      const data = response.data;

      if (data['penalty_startdate']) {
        data['penalty_startdate'] = format(parseISO(data['penalty_startdate']), 'yyyy-MM-dd');
      }

      setInitialValues(data);

      let acc = 0;
      for (let i = 1; i <= 4; i++) {
        if (data[`penalty${i}_percentage`] !== undefined && data[`penalty${i}_percentage`] !== null) {
          acc++;
          continue;
        }

        break;
      }

      setPenalizations(acc);

      setShowForm(true);
      setShowClientInfo(FORM_USER_INFO_DONE);
    } catch (error) {
      // TODO: Añadir handler para manejo de este tipo de errores.
      console.log(error);
    }
  };

  if (FORM_TYPE_ADD === FORM_TYPE && !initialValues) {
    setInitialValues({
      id: "",
      fullname: "",
      description: "",

      // Client fields
      clientid: "",
      identifier: "",
      name: "",
      first_lastname: "",
      second_lastname: "",
      email: "",
      phone: "",
      academicprogram: "",
      status: "",

      // Concepts
      concepts: [],

      penalty_startdate: format(new Date(), 'yyyy-MM-dd'),
      penalty1_percentage: "",
      penalty2_percentage: "",
      penalty3_percentage: "",
      penalty4_percentage: "",
      penalty1_graceperiod: "",
      penalty2_graceperiod: "",
      penalty3_graceperiod: "",
      penalty4_graceperiod: "",
    });

    if (!showForm) {
      setShowForm(true);
    }
  }

  if (FORM_TYPE_EDIT === FORM_TYPE && !initialValues) {
    getInitialValues(match.params.orderid);
  }

  const removeHandler = async (remove, row, index, helpers) => {
    const { setSubmitting } = helpers;
    if (FORM_TYPE_ADD === FORM_TYPE) {
      remove(index);
      return;
    }

    if (FORM_TYPE_EDIT === FORM_TYPE) {
      try {
        const token = getSessionToken();
        await deleteOrderItem(token, row.id);
        remove(index);
      } catch (error) {
        apiErrorHandler(error, helpers);
        setSubmitting(false);
      }
      return;
    }
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card className={classes.root}>
                <CardHeader
                  title={
                    FORM_TYPE === FORM_TYPE_ADD
                      ? "Añadir orden de pago"
                      : "Editar orden de pago"
                  }
                  subheader="Administre y genere ordenes de pago"
                />
                <CardContent>
                  {!showForm && (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  )}
                  {showForm && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validation}
                      onSubmit={submitHandler}
                    >
                      {(helpers) => {
                        const {
                          isSubmitting,
                          values,
                          status,
                          setFieldValue,
                          setSubmitting,
                        } = helpers;

                        return (
                          <Form>
                            {status && (
                              <Box mt={4} mb={2}>
                                <Alert variant="outlined" severity="error">
                                  {status}
                                </Alert>
                              </Box>
                            )}
                            <Box mb={4}>
                              <Grid container spacing={3}>
                                <Grid item xs={FORM_TYPE_ADD === FORM_TYPE ? 12 : 6}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    type="text"
                                    name={`description`}
                                    label="Descripción de orden"
                                    variant="standard"
                                    required
                                  />
                                </Grid>

                                {FORM_TYPE_ADD !== FORM_TYPE && <Grid item xs={6}>
                                  <InputLabel htmlFor="order-status">Estado</InputLabel>
                                  <Field
                                    component={Select}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                      id: 'order-status',
                                    }}
                                    name={`status`}
                                    variant="standard"
                                    required
                                    //disabled={values.status == "completed"}
                                  >
                                    <MenuItem value={'pending'}>Pendiente</MenuItem>
                                    <MenuItem value={'completed'}>Pagada</MenuItem>
                                    <MenuItem value={'canceled'}>Cancelada</MenuItem>
                                  </Field>
                                </Grid>}
                              </Grid>
                            </Box>

                            <Grid container spacing={3}>
                              <Grid item xs={7}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  type="text"
                                  name="fullname"
                                  label="Nombre del cliente"
                                  helperText="Nombre del solicitante."
                                  disabled
                                  required
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <Box textAlign="right">
                                  <Button
                                    type="button"
                                    variant="text"
                                    color="secondary"
                                    startIcon={<AddCircleOutlineIcon />}
                                    disabled={isSubmitting}
                                    onClick={() =>
                                      setClientSelectorDialog(true)
                                    }
                                  >
                                    Seleccionar cliente
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>

                            <Box display="flex" justifyContent="center">
                              <ErrorMessage
                                name="clientid"
                                component={Alert}
                                severity="error"
                              />
                            </Box>

                            {showClientInfo !== null && (
                              <React.Fragment>
                                <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                    {showClientInfo ===
                                      FORM_USER_INFO_LOADING && (
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <CircularProgress />
                                        </Box>
                                      )}
                                    {showClientInfo === FORM_USER_INFO_DONE && (
                                      <FormUserData showAllFields disabled />
                                    )}
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            )}

                            <Box mb={4}>
                              <FieldArray
                                name="concepts"
                                render={(array_helpers) => {
                                  const { push, remove } = array_helpers;
                                  return (
                                    <React.Fragment>
                                      <Box textAlign="right">
                                        <Button
                                          type="button"
                                          variant="text"
                                          color="secondary"
                                          startIcon={<AddCircleOutlineIcon />}
                                          disabled={isSubmitting}
                                          onClick={(row) => {
                                            setConceptSelectorDialog(true);
                                          }}
                                        >
                                          Añadir conceptos
                                        </Button>

                                        <ConceptsSelectorDialog
                                          open={showConceptDialog}
                                          selectionHandler={(row) => {
                                            const { id, ...rest } = row;
                                            push({
                                              conceptid: id,
                                              ...rest,
                                              quantity: ''
                                            });
                                            setConceptSelectorDialog(false);
                                          }}
                                          doCancel={() =>
                                            setConceptSelectorDialog(false)
                                          }
                                        />
                                      </Box>

                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <ErrorMessage
                                          name="concepts"
                                          component={Alert}
                                          severity="error"
                                        />
                                      </Box>
                                      <Grid item xs={12} mb={4}>
                                        {(!values ||
                                          !values.concepts ||
                                          values.concepts.length === 0) && (
                                            <Box textAlign="center" p={2}>
                                              <Typography
                                                variant="subtitle1"
                                                component="span"
                                                color="textSecondary"
                                              >
                                                No se han añadido conceptos
                                              </Typography>
                                            </Box>
                                          )}
                                        {values &&
                                          values.concepts.length > 0 &&
                                          values.concepts.map((concept, i) => (
                                            <Grid
                                              container
                                              spacing={3}
                                              key={i}
                                              className={classes.conceptItem}
                                            >
                                              <input
                                                type="hidden"
                                                name={`concepts.${i}.id`}
                                              />
                                              <input
                                                type="hidden"
                                                name={`concepts.${i}.conceptid`}
                                              />
                                              <Grid item xs={12} sm={12}>
                                                <Field
                                                  component={TextField}
                                                  fullWidth
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  type="text"
                                                  name={`concepts.${i}.name`}
                                                  label="Nombre del concepto"
                                                  variant="filled"
                                                  disabled
                                                  required
                                                />
                                              </Grid>
                                              <Grid item xs={4} sm={4}>
                                                <Field
                                                  component={TextField}
                                                  fullWidth
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  type="text"
                                                  name={`concepts.${i}.quantity`}
                                                  label="Cantidad"
                                                  required
                                                />
                                              </Grid>
                                              <Grid item xs={4} sm={4}>
                                                <Field
                                                  component={TextField}
                                                  fullWidth
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  type="text"
                                                  name={`concepts.${i}.price`}
                                                  label="Precio"
                                                  disabled
                                                  required
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        $
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  variant="filled"
                                                />
                                              </Grid>
                                              <Grid item xs={4} sm={4}>
                                                <Field
                                                  component={TextField}
                                                  fullWidth
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  type="text"
                                                  name={`concepts.${i}.tax`}
                                                  label="Impuestos"
                                                  disabled
                                                  required
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        %
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  variant="filled"
                                                />
                                              </Grid>

                                              <Grid item xs={8} sm={8}>
                                                <Typography
                                                  variant="subtitle2"
                                                  component="span"
                                                  color="textSecondary"
                                                >
                                                  {concept.description}
                                                </Typography>
                                              </Grid>

                                              <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                container
                                                direction="row"
                                                justify="flex-end"
                                                alignItems="flex-end"
                                              >
                                                <Grid item>
                                                  <Button
                                                    type="button"
                                                    variant="text"
                                                    color="secondary"
                                                    startIcon={
                                                      <RemoveCircleOutlineIcon />
                                                    }
                                                    disabled={isSubmitting}
                                                    onClick={() =>
                                                      removeHandler(
                                                        remove,
                                                        concept,
                                                        i,
                                                        helpers
                                                      )
                                                    }
                                                  >
                                                    Remover
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          ))}
                                      </Grid>
                                    </React.Fragment>
                                  );
                                }}
                              />
                            </Box>

                            <Box textAlign="right" mb={2}>
                              <Grid container>
                                <Grid item xs={12} md={8}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    type="date"
                                    name={`penalty_startdate`}
                                    label={`Fecha de inicio para penalización`}
                                    helperText="Define la fecha desde la cual se debe contabilizar los dias para el calculo de penalización."
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Button
                                    type="button"
                                    variant="text"
                                    color="secondary"
                                    startIcon={<AddCircleOutlineIcon />}
                                    disabled={penalizations >= 4}
                                    onClick={() => setPenalizations((prev) => prev + 1)}
                                  >
                                    Añadir penalización
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>

                            {!penalizations && <Box textAlign="center" p={2} mb={4}>
                              <Typography
                                variant="subtitle1"
                                component="span"
                                color="textSecondary"
                              >
                                No se han añadido penalizaciones
                              </Typography>
                            </Box>}

                            {!!penalizations && <Box mb={2}>
                              <Grid container spacing={3}>
                                {printPenalizationFields(penalizations, setPenalizations, setFieldValue)}
                              </Grid>
                            </Box>}

                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <Button
                                  type="button"
                                  fullWidth
                                  variant="contained"
                                  color="inherit"
                                  className={classes.submit}
                                  onClick={() => setShowConfirmDialog(true)}
                                >
                                  Cancelar
                                </Button>
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  disabled={isSubmitting}
                                >
                                  Guardar
                                </Button>
                              </Grid>
                            </Grid>

                            <ClientSelectorDialog
                              open={showClientDialog}
                              selectionHandler={(row) => {
                                // Wait until user info is updated.
                                setSubmitting(true);
                                // Remove the modal
                                setClientSelectorDialog(false);

                                // Update username.
                                setFieldValue("fullname", row.fullname);

                                // Start client info spinner
                                setShowClientInfo(FORM_USER_INFO_LOADING);

                                updateClientInfo(setFieldValue, row.id, () => {
                                  setShowClientInfo(FORM_USER_INFO_DONE);
                                  setSubmitting(false);
                                });
                              }}
                              doCancel={() => setClientSelectorDialog(false)}
                            />
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.boxImageConcept}>
                <img
                  className={classes.imageConcept}
                  src={ImageConcept}
                  alt="Conceptos"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <CancelConfirmDialog
        hide={() => setShowConfirmDialog(false)}
        handleAccept={() => {
          back();
        }}
        open={showConfirmDialog}
      />
    </React.Fragment >
  );
}

// Prop types definition.
OrdersForm.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  match: PropTypes.object.isRequired,
};

/**
 * Client data info state update handler.
 *
 * @param updateField
 * @param id
 * @param done
 */
async function updateClientInfo(updateField, id, done) {
  try {
    const token = getSessionToken();
    const response = await getClient(token, id);
    const data = response.data;

    Object.keys(data).forEach((field) => {
      const formField = field === "id" ? "clientid" : field;

      updateField(formField, data[field]);
    });

    done();
  } catch (error) {
    console.log(error);
  }
}

export default withBasicLayout(withStyles(styles)(OrdersForm));

function getPenalizationValidations(iterator) {
  let validation = {};

  if (!iterator) {
    return validation;
  }

  validation["penalty_startdate"] = Yup.date();

  for (var i = 1; i <= iterator; i++) {
    validation = {
      ...validation,
      [`penalty${i}_graceperiod`]: Yup
        .number()
        .typeError("El valor es requerido")
        .required("El valor es requerido")
        .positive("El valor debe ser mayor que 0"),
      [`penalty${i}_percentage`]: Yup
        .number()
        .typeError("El valor es requerido")
        .required("El valor es requerido")
        .positive("El valor debe ser mayor que 0")
        .max(100, "El porcentaje del impuesto no puede ser mayor al 100%")
    };
  }

  return validation;
}

function printPenalizationFields(iterator, penalizationSetter, setFieldValue) {
  const fields = [];

  if (!iterator) {
    return fields;
  }

  for (let i = 1; i <= iterator; i++) {
    fields.push(<React.Fragment key={i}>
      <Grid item xs={12} md={5}>
        <Field
          component={TextField}
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          name={`penalty${i}_percentage`}
          label={`Penalización ${i}`}
          helperText="Porcentaje a penalizar"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                %
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Field
          component={TextField}
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          name={`penalty${i}_graceperiod`}
          label={`Penalización ${i} - Días de gracia`}
          helperText="Una vez que hayan transcurrido esta cantidad de días naturales despues de crear el pedido, se añadirá esta penalización. Las penalizaciones no son acumulables."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                días
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          type="button"
          variant="text"
          color="secondary"
          startIcon={<RemoveCircleOutlineIcon />}
          onClick={() => {
            setFieldValue(`penalty${i}_percentage`, "");
            setFieldValue(`penalty${i}_graceperiod`, "");
            penalizationSetter((prev) => prev - 1);
          }}
        >
          Eliminar
        </Button>
      </Grid>
    </React.Fragment>)
  }

  return fields;
}

const styles = (theme) => ({
  boxImageConcept: {
    height: "100%",
    minHeight: 300,
    width: "100%",
  },
  imageConcept: {
    height: "100%",
    objectFit: "cover",
    width: "100%",
  },
});

export default styles;

export default (theme) => ({
  heading: {
    marginBottom: theme.spacing(3),
  },
  topData: {
    justifyContent: "space-between",
    display: "flex",
    flexFlow: "row wrap",
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  topDataLeft: {
    flexGrow: 1,
    width: "59.5%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    "&>div": {
      height: "100%",
    },
  },
  addFromCodeBox: {
    width: '100%'
  },
  fieldContainer: {
    width: "49%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    marginBottom: theme.spacing(2),
    "&>div": {
      width: "100%",
    },
  },
  fieldContainerFull: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "&>div": {
      width: "100%",
    },
  },
  topDataRight: {
    flexGrow: 2,
    width: "29.5%",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
    "&>div": {
      height: "100%",
    },
  },
  table: {
    marginBottom: theme.spacing(4),
  },
  dataDetails: {
    display: "flex",
    flexFlow: "row wrap",
    "&>div": {
      marginBottom: theme.spacing(2),
      width: "50%",
    },
  },
  policies: {
    paddingBottom: theme.spacing(1),
  },
  policiesMedia: {
    height: 160,
  },
  policiesContent: {
    padding: 0,
    "& ul": {
      padding: 0,
    },
  },
  actions: {
    textAlign: "center",
    "& button": {
      margin: theme.spacing(0, 2),
    },
  },
});

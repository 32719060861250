import { Box, Toolbar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toggleAdminMenuAction } from "../../actions/admin-actions";
import { logOutUser } from "../../actions/auth-actions";
import { logOutClient } from '../../actions/my-payments-actions';
import styles from "./header-styles";

function Header(props) {
  const { classes } = props;
  const history = useHistory();
  const session = useSelector((state) => state.auth.session);
  const name = useSelector((state) => state.auth.me && state.auth.me.name);
  const isClientSessionActive = useSelector((state) => !!state.myPayments.id);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleAdminMenuAction());
  };

  const handleClientSession = () => {
    dispatch(logOutClient());
    window.location = "/";
  };

  return (
    <div className={clsx(classes.root, "no-print")}>
      <AppBar position="fixed">
        <Toolbar>
          {session && (
            <Box className={classes.container}>
              <Button
                color="inherit"
                onClick={() => {
                  handleClick();
                }}
              >
                <Icon color="inherit">menu</Icon>
              </Button>
            </Box>
          )}
          <Link to="/" className={classes.title}>
            <Typography variant="h6" component="span">
              UIW Campus Bajío
            </Typography>
          </Link>
          {name && (
            <Typography
              variant="subtitle1"
              className={classes.welcome}
              component="span"
            >
              Bienvenido {name}
            </Typography>
          )}
          {!isClientSessionActive && session && (
            <Button
              color="inherit"
              onClick={() => {
                dispatch(logOutUser());
                window.location = "/";
              }}
            >
              Cerrar Sesión
            </Button>
          )}
          {!isClientSessionActive && !session && (
            <Button
              color="inherit"
              onClick={() => {
                history.push("/login");
              }}
            >
              Iniciar Sesión
            </Button>
          )}
          {isClientSessionActive && (
            <Button color="inherit" onClick={() => handleClientSession()}>
              Salir
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(Header);


export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: 'white',
    textDecoration: 'none',
    '&:hover, &:focus': {
      color: 'white',
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      '& span': {
        fontSize: '0.9rem !important'
      }
    },
  },
  welcome: {
    position: 'relative',
    top: -2,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(6),
  }
});

import { create, createUsingBearerToken } from "./axios-api.factory";
import { AxiosResponse } from 'axios'; // eslint-disable-line no-unused-vars

/**
 * Logs the user.
 *
 * @return {AxiosResponse}
 */
export async function loginAPI(credentials) {
  const axios = create();

  return await axios.post("/api/login", credentials);
}

export async function logoutAPI(token) {
  const axios = createUsingBearerToken(token);

  try {
    await axios.post("/api/logout");
  } catch {
    throw new Error("Error al cerrar sesión");
  }
}

export async function meAPI(token) {
  const axios = createUsingBearerToken(token);

  let response;

  try {
    response = await axios.get("/api/me");
  } catch {
    throw new Error("Error al recuperar datos de sesión del usuario.");
  }

  if (response.status && response.status !== 200) {
    throw new Error("No ha sido posible cargar los datos del usuario.");
  }

  return response.data;
}

import { createUsingBearerToken } from "./axios-api.factory";

export async function postClient(token, values) {
  const axios = createUsingBearerToken(token);

  return await axios.post("/api/clients/add", values);
}

export async function updateClient(token, values) {
  const axios = createUsingBearerToken(token);

  return await axios.put("/api/clients/update", values);
}

export async function getClient(token, clientId) {
  const axios = createUsingBearerToken(token);

  return await axios.get(`/api/clients/get`, { params: { id: clientId } });
}

export async function getAllClient(token, query) {
  const axios = createUsingBearerToken(token);

  let response;
  try {
    response = await axios.post("/api/clients/all", query);
  } catch (e) {
    throw new Error("Error al recuperar los clientes.");
  }

  return response.data;
}

export default (theme) => ({
  heading: {
    marginBottom: theme.spacing(4),
  },
  userName: {
    textTransform: "uppercase",
  },
  informationBox: {
    display: "flex",
    flexFlow: "row wrap",
  },
  informationBoxLeft: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      marginRight: "unset",
      width: "100%",
    },
  },
  informationBoxRight: {
    textAlign: "center",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
      width: "100%",
    },
  },
  inputField: {
    width: "100%",
  },
  qr: {
    height: "100%",
    margin: theme.spacing(1, 0),
    maxHeight: 150,
    width: "100%",
  },
  actions: {
    marginTop: theme.spacing(4),
    textAlign: "center",
    "& button": {
      margin: theme.spacing(0, 2),
    },
  },
  policiesMedia: {
    height: 160,
  },
  fieldsFlex: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
  },
  fieldContainer: {
    width: "49%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    marginBottom: theme.spacing(2),
    "&>div": {
      width: "100%",
    },
  },
  fieldContainerFull: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "&>div": {
      width: "100%",
    },
  },
  concepts: {
    marginTop: theme.spacing(3),
  },
});

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  withStyles
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import { getSessionToken } from "../../actions/auth-actions";
import { apiErrorHandler } from "../../api/api-error-handler";
import { postAddTemplateToOrder } from "../../api/orders-api";
import styles from "./my-payments-styles";

function TemplatesForm(props) {
  const { classes } = props;

  const [initialValues, setInitialValues] = useState(null);
  // Used when loading the form.
  const [showForm, setShowForm] = useState(false);

  const clientid = useSelector((state) => state.myPayments.id);

  // Form validation YUP object.
  const validation = Yup.object({
    identifier: Yup.string()
      .matches(
        /[a-zA-Z0-9]+/,
        "La constraseña solo puede tener caracteres alfanuméricos (a-z, A-Z, 0-9).")
      .max(128, "La longitud máxima es de 128 caracteres alfanuméricos.")
      .required("Campo requerido"),
  });

  // Submit handler
  const submitHandler = async (values, helpers) => {
    const { setSubmitting } = helpers;
    try {
      const token = getSessionToken();
      const identifier = values.identifier;
      await postAddTemplateToOrder(token, identifier, clientid);
      window.location.reload();
    } catch (error) {
      apiErrorHandler(error, helpers);
      setSubmitting(false);
    }
  };

  if (!initialValues) {
    setInitialValues({
      identifier: "",
    });

    if (!showForm) {
      setShowForm(true);
    }
  }

  return (
    <React.Fragment>
      {!showForm && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {showForm && (
        <Formik
          initialValues={initialValues}
          validationSchema={() => {
            return validation;
          }}
          onSubmit={submitHandler}
        >
          {(helpers) => {
            const {
              isSubmitting,
              status,
            } = helpers;

            return (
              <Form>
                {status && (
                  <Box mt={4} mb={2}>
                    <Alert variant="outlined" severity="error">
                      {status}
                    </Alert>
                  </Box>
                )}
                <Box mb={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={10}>
                      <Field
                        component={TextField}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        name={`identifier`}
                        label="Código identificador"
                        description="Código identificador de la orden a añadir"
                        variant="standard"
                        required
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isSubmitting}
                    >
                      Guardar
                    </Button>
                  </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </React.Fragment>
  );
}

// Prop types definition.
TemplatesForm.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(TemplatesForm);

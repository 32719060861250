import Axios from "axios";

/**
 * Base Axios API factory.
 *
 * @param {AxiosRequestConfig} options
 */
export function create(options) {
  return Axios.create({
    ...options,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });
}

/**
 * Base Axios API factory.
 *
 * @param {AxiosRequestConfig} options
 */
export function createUsingBearerToken(token, options) {
  options = options || {};

  if (!token) {
    throw new Error("Bearer token can't be null or undefined");
  }

  return Axios.create({
    ...options,
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      ...(options.headers || {}),
      Authorization: `Bearer ${token}`,
    },
  });
}

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Container,
  Typography,
  withStyles
} from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from "prop-types";
import QRcode from "qrcode.react";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { getPaymentConfiguration, getPaymentState, requestPayment } from "../../api/my-payments-api";
import { FormUserPublicData } from "../forms/form-user-data-public";
import { FormUserPaymentsPublic } from "../forms/form-user-payments-public";
import { withBasicLayout } from "../layout/basic-layout";
import styles from "./payment-details-styles";

// import { format } from 'date-fns'
// import { es } from 'date-fns/locale'

function PaymentDetails(props) {
  const { classes } = props;
  const history = useHistory();

  // Launcher state
  const [showForm, setShowForm] = useState(false);

  // Payment identifier
  const { paymentid } = useParams();

  // Client identifier
  const clientid = useSelector((state) => state.myPayments.id);

  const [initialValues, setInitialValues] = useState(null);

  const getInitialValues = async () => {
    try {
      const response = await getPaymentState(clientid, paymentid);

      setInitialValues(response.data);
      setShowForm(true);

      if (window.location.href.includes("confirm")) {
        setTimeout(() => window.print(), 1500);
      }

    } catch (error) {
      console.log(error);
    }
  };

  // const formatDate = (date) => {
  //   return format(date, '', {locale: es})
  // }

  const checkoutLauncher = async () => {
    try {
      const configuration = await getPaymentConfiguration();
      const request = await requestPayment(
        clientid,
        initialValues.id,
        window.location.href.replace("/details", "/confirm"));

      window.doComplete = (response) => {
        toast.success("Operacion de pago completada.");
        console.log(response);
      }

      window.doError = (error) => {
        toast.error("Error al lanzar plataforma de pagos. Si el problema persiste contacte al administrador.");
        console.log(error);
      }

      window.doCancel = () => {
        toast.error("Pago cancelado.");
      }

      window.Checkout.configure({
        session: {
          id: request.data
        },
        order: {
          description: initialValues.description,
        },
        interaction: {
          locale: "es",
          merchant: {
            name: configuration.data.merchantname,
            address: {
              line1: configuration.data.merchantaddress
            },
            phone: configuration.data.merchantphone,
            email: configuration.data.merchantemail
          },
          displayControl: {
            shipping: 'HIDE',
            billingAddress: 'HIDE'
          }
        },
      });

      window.Checkout.showLightbox();
    } catch (error) {
      toast.error("Error al lanzar plataforma de pagos. Si el problema persiste contacte al administrador.");
      console.log(error);
    }
  };

  if (!initialValues) {
    getInitialValues();
  }

  return (
    <Fragment>
      <Container maxWidth="lg">
        <Box className={classes.informationBox}>
          <Box className={classes.informationBoxLeft}>
            <Card variant="elevation">
              <CardHeader
                title="Universidad Incarnate Word Campus Bajío"
                subheader="Pago por internet"
                className={classes.policies}
              />
              <CardContent>
                <FormUserPublicData />
              </CardContent>
            </Card>

            {(!!initialValues && !!initialValues.penalty) && <Box mt={3}>
              <Alert variant="outlined" severity="warning">
                Esta orden de pago ha sido penalizada porque se ha superado su fecha de pago.
              </Alert>
            </Box>}

            <Card variant="elevation" className={classes.concepts}>
              <CardHeader
                title="Conceptos de pago"
                subheader="Detalle de concepto de pago"
              />
              <CardContent>
                {!initialValues && <Box textAlign="center">
                  <CircularProgress />
                </Box>}
                {!!initialValues && <FormUserPaymentsPublic />}
              </CardContent>
            </Card>
          </Box>
          <Box className={classes.informationBoxRight}>
            <Card variant="elevation">
              <CardMedia
                className={classes.policiesMedia}
                image="/img/bg-college.jpg"
                title="Contemplative Reptile"
              />
              <CardContent className={classes.policiesContent}>
                {!showForm && (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                )}
                {showForm && (
                  <React.Fragment>
                    <Typography
                      variant="body1"
                      component="p"
                      color="textSecondary"
                    >
                      Folio de pago:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      color="textSecondary"
                    >
                      {paymentid}
                    </Typography>
                    <QRcode
                      value={paymentid}
                      renderAs="svg"
                      className={classes.qr}
                    />
                    <Typography
                      variant="body1"
                      component="h6"
                      color="textSecondary"
                    >
                      Importe total:
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      color="textSecondary"
                    >
                      ${initialValues.total} MXN
                    </Typography>

                    <Box mt={2}>
                      {(["processing", "pending"]).includes(initialValues.status) && (
                        <Button
                          className={classes.submitBtn}
                          variant="contained"
                          color="primary"
                          disabled={initialValues.status !== "pending"}
                          onClick={() => checkoutLauncher()}
                        >
                          Pagar
                        </Button>
                      )}

                      {initialValues.status === "processing" && (
                        <Box mt={4} mb={2}>
                          <Alert variant="outlined" severity="info">
                            Hay un proceso pendiente para la orden, este proceso debe ser liberado en máximo 3 minutos. Si el problema persiste contacte a su administrador.
                          </Alert>
                        </Box>
                      )}

                      {initialValues.status === "completed" && (
                        <Box mt={4} mb={2}>
                          <Alert variant="outlined" severity="success">
                            Esta orden ya ha sido pagada.
                          </Alert>
                        </Box>
                      )}

                      {(initialValues.status === "canceled" || initialValues.status === "error") && (
                        <Box mt={4} mb={2}>
                          <Alert variant="outlined" severity="danger">
                            La orden fue cancelada o ha fallado. Si el problema persiste contacte a su administrador.
                          </Alert>
                        </Box>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box className={clsx(classes.actions, "no-print")}>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => history.push("/my-payments")}
          >
            Volver
          </Button>
        </Box>
      </Container>
    </Fragment>
  );
}

PaymentDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withBasicLayout(withStyles(styles)(PaymentDetails));

import { Box, Container, withStyles } from '@material-ui/core';
import PropTypes from "prop-types";
import React from "react";
import styles from './admin-reports-styles';
import { withBasicLayout } from '../layout/basic-layout';

function Concepts(props) {
  const { classes } = props;

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        Reports
      </Container>
    </Box>
  );
}

Concepts.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withBasicLayout(withStyles(styles)(Concepts));

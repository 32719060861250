import { red, grey } from '@material-ui/core/colors';

const styles = (theme) => ({
  boxImageConcept: {
    height: "100%",
    minHeight: 300,
    width: "100%",
  },
  imageConcept: {
    height: "100%",
    objectFit: "cover",
    width: "100%",
  },
  conceptItem: {
    borderBottom: `2px solid ${red[900]}`,
    background: grey[200],
    marginTop: theme.spacing(3),
    padding: theme.spacing(1, 2),
  }
});

export default styles;

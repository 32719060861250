import backgroundImage from '../../assets/img/footer_background.png';

export default (theme) => ({
  root: {
    background: '#2f3e51',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'auto 110%;',
    backgroundPosition: '50%',
    backgroundReapeat: 'no-repeat',
    color: 'white',
    padding: theme.spacing(2, 4),

    '& a': {
      color: 'white',
      '&:hover, &:focus': {
        color: 'white',
        textDecoration: 'none',
      },
    },
  },
  widgetBox: {
    padding: theme.spacing(2, 2),
    fontSize: '.758rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  widgetLogo: {
    maxWidth: 270,
    marginBottom: theme.spacing(2),
    width: '98%',
  },
  link: {
    color: 'white',
    '&:hover, &:focus': {
      color: 'white',
      textDecoration: 'none',
    },
  },
  linkBox: {
    color: 'white',
    display: 'block',
    marginBottom: theme.spacing(2),
    '&:hover, &:focus': {
      color: 'white',
      textDecoration: 'none',
    },
  },
  footerBottom: {
    alignItems: 'center',
    borderTop: '1px solid #35b4ad',
    fontSize: '0.758rem',
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'row wrap',
      textAlign: 'center',
    },
    '& > div:first-of-type': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
  footerLinks: {
    display: 'flex',
    flexFlow: 'row wrap',
    '& > div': {
      padding: theme.spacing(0, 2, 1),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }
});

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  withStyles
} from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { withBasicLayout } from '../../layout/basic-layout'
import styles from "./import-form-styles";
import * as Yup from "yup";
import { getSessionToken } from "../../../actions/auth-actions";
import { apiErrorHandler } from "../../../api/api-error-handler";
import CancelConfirmDialog from "../../ui/cancel-confirm-dialog";
import image from "../../../assets/img/concepts-form-img.JPG"
import { postImport } from "../../../api/import";
import download from 'downloadjs';
import { toast } from 'react-toastify';

const validation = Yup.object({
  file: Yup.mixed().required('A file is required')
    .test('fileFormat', 'El archivo debe tener extensión .xlsx y no pesar más de 4MB.', (value: any) => value && ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value.type) && value.size <= (1024 * 1024 * 1024 * 10))
});

interface FormValues {
  file?: any
}

const ImportFormComponent = (props: any) => {
  const { classes, match } = props;

  const history = useHistory();

  const [showDialog, setShowDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState<FormValues|null>(null);
  const back = () => history.push("/");

  const submitHandler = async (values: any, helpers: FormikHelpers<any>) => {
    const { setSubmitting, resetForm } = helpers;
    try {
      let data = new FormData();
      data.append('file', values.file);

      const token = getSessionToken();
      const response = await postImport(token, data);

      const content = response.headers['content-type'];
      download(response.data, 'result.xlsx', content);
      toast.success("Documento procesado.");
    } catch (error) {
      toast.success("Error al procesar el documento.");
      apiErrorHandler(error, helpers);
      setSubmitting(false);
    }
  };

  if (!initialValues) {
    setInitialValues({});

    if (!showForm) {
      setShowForm(true);
    }
  }

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card className={classes.root}>
                <CardHeader
                  title="Importar datos"
                  subheader="Importar y actualizar registros de clientes, conceptos u ordenes de pago."
                />
                <CardContent>
                  {!showForm && (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  )}

                  {showForm && (
                    <Formik
                      initialValues={initialValues as FormValues}
                      validationSchema={validation}
                      onSubmit={submitHandler}
                    >
                      {({ isSubmitting, values, setFieldValue }) => {
                        return (
                          <Form encType="">
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  type="file"
                                  name="file"
                                  label="Archivo a importar"
                                  helperText="Seleccione el archivo a importar. El archivo debe tener extensión .xlsx y no pesar más de 4MB."
                                  required
                                  accepts
                                  value={undefined}
                                  inputProps={{
                                    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  }}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const target = e.target as HTMLInputElement;

                                    if (!target.files) {
                                      return;
                                    }

                                    setFieldValue("file", Array.from(target.files)[0]);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  type="button"
                                  fullWidth
                                  variant="contained"
                                  color="inherit"
                                  className={classes.submit}
                                  disabled={isSubmitting}
                                  onClick={() => setShowDialog(true)}
                                >
                                  Cancelar
                                </Button>
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  disabled={isSubmitting}
                                >
                                  Guardar
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={4}>
                <div className={classes.boxImageConcept}>
                  <img
                    className={classes.imageConcept}
                    src={image}
                    alt="Importar datos"
                  />
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <CancelConfirmDialog
        hide={() => setShowDialog(false)}
        handleAccept={() => {
          back();
        }}
        open={showDialog}
      />
    </React.Fragment>
  );
}

export default withBasicLayout(withStyles(styles as any)(ImportFormComponent));

import { Box, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import styles from "./basic-layout-styles";
import Footer from "./footer";
import Header from "./header";
import AdminDrawer from './admin-drawer';

/**
 * HOC que añade el layout básico
 */
export function withBasicLayout(Component) {
  const ComponentWithLayout = (props) => {
    const { classes, ...other } = props;

    return (
      <Fragment>
        <Header {...other} />
        <AdminDrawer {...other} />
        <Box className={classes.container}>
          <Component {...other} />
        </Box>
        <Footer />
      </Fragment>
    );
  };

  ComponentWithLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  return withStyles(styles)(ComponentWithLayout);
}

import React from 'react';
import ReactDOM from 'react-dom';
import App from "./components/app";
import "./index.scss";
import reportWebVitals from './reportWebVitals';
import { isDev } from "./utils/environment";

if (isDev()) {
  console.log(`👻 The service endpoint is: ${process.env.REACT_APP_API_BASE_URL}`);
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

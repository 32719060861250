import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  withStyles,
  Paper,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { getSessionToken } from "../../actions/auth-actions";
import { deleteConcept, getAllConcept } from "../../api/concepts-api";
import { withBasicLayout } from "../layout/basic-layout";
import GenericTable from "../ui/generic-table";
import styles from "./admin-concepts-styles";
import { toast } from 'react-toastify';

function totalRenderer(rowData) {
  return `$${rowData.price} MXN`;
}
function Concepts(props) {
  const { classes } = props;

  const history = useHistory();

  const tRef = React.useRef(GenericTable);

  const fetchData = async (query) => {
    try {
      const token = getSessionToken();
      const clients = await getAllConcept(token, query);
      return {
        data: clients.data,
        page: clients.current_page - 1,
        totalCount: clients.total,
      };
    } catch {
      console.log("Error al obtener los conceptos");
    }
  };

  const columns = [
    { title: "Fecha de creación", field: "created_at", type: "datetime", defaultSort: "desc" },
    { title: "Nombre", field: "name" },
    { title: "Descripción", field: "description", type: "numeric" },
    { title: "Precio (antes de impuestos)", field: "price", type: "numeric", render: totalRenderer },
  ];

  const actions = [
    {
      icon: "find_in_page",
      tooltip: "Ver",
      onClick: (action, row) => {
        history.push(`/admin/concepts/${row.id}/edit`);
      },
    },
    {
      icon: "clear",
      tooltip: "Eliminar",
      onClick: async (action, row) => {
        try {
          const table = tRef.current;
          const materialTable = table.getTableRef().current;

          const token = getSessionToken();
          await deleteConcept(token, row.id);

          materialTable.onQueryChange();
          toast.warning(
            "Concepto eliminado."
          );
        } catch (error) {
          toast.error(
            "Error al borrar elemento. Si el problema persiste contacte a su administrador."
          );
        }
      },
    },
  ];

  return (
    <Box className={classes.root}>
      <Container>
        <Box mb={3}>
          <Card variant="elevation">
            <CardHeader
              title="Conceptos de pago"
              subheader="Este listado muestra todas las opciones de conceptos que se pueden añadir a ordenes de pago."
              className={classes.policies}
            />
            <CardContent className={classes.fieldsFlex}>
              <Button
                variant="text"
                color="secondary"
                className={classes.button}
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => history.push("/admin/concepts/add")}
              >
                Añadir concepto
              </Button>
            </CardContent>
          </Card>
        </Box>
        <Paper elevation={2}>
          <GenericTable
            columns={columns}
            fetch={fetchData}
            actions={actions}
            ref={tRef}
          ></GenericTable>
        </Paper>
      </Container>
    </Box>
  );
}

Concepts.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withBasicLayout(withStyles(styles)(Concepts));

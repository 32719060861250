import { loginAPI, logoutAPI, meAPI } from "../api/session-api";
import * as types from "./auth-action-types";

const SESSION_TOKEN = "JWT";

export function loginAction() {
  return { type: types.LOG_IN_SUCCESS };
}

export function logoutAction() {
  return { type: types.LOG_OUT };
}

export function setMeAction(payload) {
  return { type: types.SET_ME, payload };
}

export function removeMeAction() {
  return { type: types.REMOVE_ME };
}

export function updateMe() {
  return async function (dispatch) {
    const token = getSessionToken();
    try {
      const data = await meAPI(token);
      dispatch(setMeAction(data));
    } catch (error) {
      dispatch(logoutAction());
    }
  };
}

export function loginUser(values) {
  return async function (dispatch) {
    const response = await loginAPI(values);

    sessionStorage.setItem(SESSION_TOKEN, response.data.token);

    await dispatch(loginAction());
    await dispatch(updateMe());
  };
}

export function logOutUser() {
  return async function (dispatch) {
    removeSessionToken();

    const token = getSessionToken();
    if (token) {
      await logoutAPI(token);
    }

    dispatch(logoutAction());
  };
}

export function removeSessionToken() {
  sessionStorage.removeItem(SESSION_TOKEN);
}

export function getSessionToken() {
  return sessionStorage.getItem(SESSION_TOKEN);
}

export function hasSessionToken() {
  return !!sessionStorage.getItem(SESSION_TOKEN);
}

import { createUsingBearerToken } from "./axios-api.factory";

export async function deleteTemplate(token, id) {
  const axios = createUsingBearerToken(token);

  return await axios.delete(`/api/templates/delete`, { params: { id } });
}

export async function deleteTemplateItem(token, id) {
  const axios = createUsingBearerToken(token);

  return await axios.delete(`/api/templates/deleteitem`, { params: { id } });
}
export async function getTemplate(token, id) {
  const axios = createUsingBearerToken(token);

  return await axios.get(`/api/templates/get`, { params: { id } });
}

export async function updateTemplate(token, values) {
  const axios = createUsingBearerToken(token);

  return await axios.put("/api/templates/update", values);
}

export async function postTemplate(token, values) {
  const axios = createUsingBearerToken(token);

  return await axios.post("/api/templates/add", values);
}

export async function getAllTemplates(token, query) {
  const axios = createUsingBearerToken(token);

  let response;
  try {
    response = await axios.post("/api/templates/all", query);
  } catch (e) {
    throw new Error("Error al recuperar las plantillas de orden de pago.");
  }

  return response.data;
}

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import PropTypes from "prop-types";

function CancelConfirmDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { handleAccept, handleCancel, open, hide } = props;

  const doAccept = () => {
    hide();

    if (handleAccept) {
      handleAccept();
    }
  };

  const doCancel = () => {
    hide();

    if (handleCancel) {
      handleCancel();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={doCancel}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"¿Seguro que desea cancelar?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Si cancela, los datos que no haya guardado no podrán recuperarse.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={doCancel} color="inherit">
          Cancelar
        </Button>
        <Button
          onClick={doAccept}
          color="primary"
          variant="contained"
          autoFocus
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CancelConfirmDialog.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  handleAccept: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  open: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

export default CancelConfirmDialog;

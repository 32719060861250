import * as types from "./admin-actions-types";

export function showAdminMenuAction() {
  return { type: types.SHOW_ADMIN_MENU };
}

export function hideAdminMenuAction() {
  return { type: types.HIDE_ADMIN_MENU };
}

export function toggleAdminMenuAction() {
  return { type: types.TOGGLE_ADMIN_MENU };
}

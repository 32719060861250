import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Paper, withStyles
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { getSessionToken } from "../../../actions/auth-actions";
import { deleteTemplate, getAllTemplates } from '../../../api/templates-api';
import { withBasicLayout } from "../../layout/basic-layout";
import GenericTable from "../../ui/generic-table";
import styles from "./styles";

function totalRenderer(rowData) {
  return `$${rowData.total} MXN`;
}
function Orders(props) {
  const { classes } = props;

  const history = useHistory();

  const tRef = React.useRef(GenericTable);
  const [status, setStatus] = useState(null);

  const fetchData = async (query) => {
    try {
      const token = getSessionToken();
      const data = await getAllTemplates(token, query);
      return {
        data: data.data,
        page: data.current_page - 1,
        totalCount: data.total,
      };
    } catch {
      toast.error("Error al obtener las plantillas.");
    }
  };

  const columns = [
    { title: "Fecha de creación", field: "created_at", type: "datetime", defaultSort: "desc" },
    { title: "Identificador", field: "identifier" },
    { title: "Descripción", field: "description" },
    {
      title: "Publicación",
      field: "status",
      lookup: {
        public: "Pública",
        private: "Privada",
      },
    },
    { title: "Total", field: "total", type: "numeric", render: totalRenderer },
  ];

  const actions = [
    {
      icon: "find_in_page",
      tooltip: "Ver",
      onClick: (action, row) => {
        history.push(`/admin/templates/${row.id}/edit`);
      },
    },
    {
      icon: "clear",
      tooltip: "Eliminar",
      onClick: async (action, row) => {
        try {
          setStatus(null);
          const table = tRef.current;
          const materialTable = table.getTableRef().current;

          const token = getSessionToken();
          await deleteTemplate(token, row.id);

          materialTable.onQueryChange();
          toast.success("Plantilla eliminada.")
        } catch (error) {
          setStatus("Error al borrar elemento.");
        }
      },
    },
  ];

  return (
    <Box className={classes.root}>
      <Container>
        <Box mb={3}>
          <Card variant="elevation">
            <CardHeader
              title="Plantillas de orden de pago"
              subheader="Este listado muestra todas las plantillas de orden de pago del sistema."
              className={classes.policies}
            />
            <CardContent className={classes.fieldsFlex}>
              <Button
                variant="text"
                color="secondary"
                className={classes.button}
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => history.push("/admin/templates/add")}
              >
                Añadir plantilla
              </Button>
            </CardContent>
          </Card>
        </Box>
        <Box className={classes.table}>
          {status && (
            <Box mt={4} mb={2}>
              <Paper>
                <Alert variant="outlined" severity="error">
                  {status}
                </Alert>
              </Paper>
            </Box>
          )}

          <GenericTable
            columns={columns}
            fetch={fetchData}
            actions={actions}
            ref={tRef}
          />
        </Box>
      </Container>
    </Box>
  );
}

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withBasicLayout(withStyles(styles)(Orders));


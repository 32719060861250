import { getClientToken } from "../actions/my-payments-actions";
import * as actionTypes from "../actions/my-payments-action-types";
import { AnyAction } from 'redux'

const initialState = {
  id: getClientToken(),
};

const paymentReducer = (state = initialState, action: AnyAction ) => {
  switch (action.type) {
    case actionTypes.CLIENT_LOG_IN_SUCCESS:
      return { ...state, id: getClientToken() };
    case actionTypes.CLIENT_LOG_OUT:
      return { ...state, id: null };
    default:
      return state;
  }
}

export default paymentReducer;

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export const PaymentsRoute = ({
  isClientSessionActive,
  component: Component,
  ...rest
}) => (
    <Route {...rest} component={(props) => (
      !isClientSessionActive ? (
        <Redirect to="/" />
      ) : (
          <Component {...props} />
        )
    )} />
  );

const mapStateToProps = (state) => ({
  isClientSessionActive: !!state.myPayments.id
});

export default connect(mapStateToProps)(PaymentsRoute);

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  withStyles
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { getSessionToken } from "../../actions/auth-actions";
import { apiErrorHandler } from "../../api/api-error-handler";
import { getConcept, postConcept, updateConcept } from "../../api/concepts-api";
import ImageConcept from "../../assets/img/concepts-form-img.JPG";
import { withBasicLayout } from "../layout/basic-layout";
import CancelConfirmDialog from "../ui/cancel-confirm-dialog";
import styles from "./admin-concepts-form-styles";

const FORM_TYPE_ADD = 0;
const FORM_TYPE_EDIT = 1;

function ConceptsForms(props) {
  const { classes, match } = props;

  const history = useHistory();

  const [showDialog, setShowDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  // Get form type.
  const location = useLocation();
  const FORM_TYPE = location.pathname.endsWith("/add")
    ? FORM_TYPE_ADD
    : FORM_TYPE_EDIT;

  const back = () => history.push("/admin/concepts");

  const validation = Yup.object({
    name: Yup.string().required("Campo requerido"),
    price: Yup.number()
      .required("Campo requerido")
      .positive("El valor debe ser mayor que 0"),
    tax: Yup.number()
      .required("Campo requerido")
      .min("0", "El valor debe ser mayor que 0")
      .max(100, "El porcentaje del impuesto no puede ser mayor al 100%"),
  });

  const submitHandler = async (values, helpers) => {
    const { setSubmitting } = helpers;
    try {
      if (FORM_TYPE_ADD === FORM_TYPE) {
        const token = getSessionToken();
        await postConcept(token, values);
        await props.history.push("/admin/concepts");
      } else {
        const token = getSessionToken();
        await updateConcept(token, values);
        await props.history.push("/admin/concepts");
      }
    } catch (error) {
      apiErrorHandler(error, helpers);
      setSubmitting(false);
    }
  };

  const getInitialValues = async (id) => {
    try {
      const token = getSessionToken();
      const response = await getConcept(token, id);

      setInitialValues(response.data);
      setShowForm(true);
    } catch (error) {
      // TODO: Añadir handler para manejo de este tipo de errores.
      console.log(error);
    }
  };

  if (FORM_TYPE_ADD === FORM_TYPE && !initialValues) {
    setInitialValues({
      name: "",
      description: "",
      description_public: "",
      price: 0,
      tax: 0,
      tax_computed: 0,
    });

    if (!showForm) {
      setShowForm(true);
    }
  }

  if (FORM_TYPE_EDIT === FORM_TYPE && !initialValues) {
    getInitialValues(match.params.conceptid);
  }

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card className={classes.root}>
                <CardHeader
                  title={
                    FORM_TYPE === FORM_TYPE_ADD
                      ? "Añadir concepto de pago"
                      : "Editar concepto de pago"
                  }
                  subheader="Administre sus conceptos de pago"
                />
                <CardContent>
                  {!showForm && (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  )}
                  {showForm && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validation}
                      onSubmit={submitHandler}
                    >
                      {({ isSubmitting, values, setFieldValue }) => {
                        return (
                          <Form>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  type="text"
                                  name="name"
                                  label="Nombre del Concepto"
                                  helperText="Nombre del concepto (tipo de pago)."
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  type="text"
                                  name="description"
                                  label="Descripción"
                                  helperText="Descripción interna del concepto."
                                  multiline
                                  rows={4}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  type="text"
                                  name="description_public"
                                  label="Descripción pública"
                                  helperText="Descripción pública del concepto."
                                  multiline
                                  rows={4}
                                  maxLength={100}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  type="number"
                                  name="price"
                                  label="Precio"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    onChange: (e) => {
                                      setFieldValue("price", e.target.value);
                                      setFieldValue(
                                        "tax_computed",
                                        (e.target.value || 0) *
                                        (values.tax / 100 || 0)
                                      );
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  type="number"
                                  name="tax"
                                  label="Impuestos"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        %
                                      </InputAdornment>
                                    ),
                                    onChange: (e) => {
                                      setFieldValue("tax", e.target.value);
                                      setFieldValue(
                                        "tax_computed",
                                        (values.price || 0) *
                                        (e.target.value / 100 || 0)
                                      );
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  type="number"
                                  name="tax_computed"
                                  label="Impuestos (cálculo)"
                                  disabled
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  type="button"
                                  fullWidth
                                  variant="contained"
                                  color="inherit"
                                  className={classes.submit}
                                  disabled={isSubmitting}
                                  onClick={() => setShowDialog(true)}
                                >
                                  Cancelar
                                </Button>
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  disabled={isSubmitting}
                                >
                                  Guardar
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={4}>
                <div className={classes.boxImageConcept}>
                  <img
                    className={classes.imageConcept}
                    src={ImageConcept}
                    alt="Conceptos"
                  />
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <CancelConfirmDialog
        hide={() => setShowDialog(false)}
        handleAccept={() => {
          back();
        }}
        open={showDialog}
      />
    </React.Fragment>
  );
}

ConceptsForms.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  match: PropTypes.object.isRequired,
};

export default withBasicLayout(withStyles(styles)(ConceptsForms));

import { Box, withStyles } from "@material-ui/core";
import React, { Fragment } from "react";
import AdminDrawer from './admin-drawer';
import styles from "./basic-layout-styles";
import Header from "./header";
import FooterRegistroMedico from "./footer-registro-medico/footer-registro-medico";

/**
 * HOC que añade el layout básico
 */
export function withRegistroMedicoLayout(Component: React.FunctionComponent): React.FunctionComponent {
  const ComponentWithLayout = (props: any) => {
    const { classes, ...other } = props;

    return (
      <Fragment>
        <Header {...other} />
        <AdminDrawer {...other} />
        <Box className={classes.container}>
          <Component {...other} />
        </Box>
        <FooterRegistroMedico />
      </Fragment>
    );
  };

  return withStyles(styles)(ComponentWithLayout) as React.FunctionComponent;
}

import { createUsingBearerToken } from "./axios-api.factory";

export async function postConfiguration(token, values) {
  const axios = createUsingBearerToken(token);

  return await axios.post("/api/configuration/submit", values);
}

export async function getConfiguration(token) {
  const axios = createUsingBearerToken(token);

  return await axios.get(`/api/configuration/get`);
}

import { createUsingBearerToken } from "./axios-api.factory";

export async function postConcept(token, values) {
  const axios = createUsingBearerToken(token);

  return await axios.post("/api/concepts/add", values);
}

export async function updateConcept(token, values) {
  const axios = createUsingBearerToken(token);

  return await axios.put("/api/concepts/update", values);
}

export async function getConcept(token, clientId) {
  const axios = createUsingBearerToken(token);

  return await axios.get(`/api/concepts/get`, { params: { id: clientId } });
}

export async function deleteConcept(token, id) {
  const axios = createUsingBearerToken(token);

  return await axios.delete(`/api/concepts/delete`, { params: { id } });
}

export async function getAllConcept(token, query) {
  const axios = createUsingBearerToken(token);

  let response;
  try {
    response = await axios.post("/api/concepts/all", query);
  } catch (e) {
    throw new Error("Error al recuperar los coceptos de pago.");
  }

  return response.data;
}

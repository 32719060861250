import { createUsingBearerToken, create } from "./axios-api.factory";

export async function postOrder(token, values) {
  const axios = createUsingBearerToken(token);

  return await axios.post("/api/orders/add", values);
}

export async function postAddTemplateToOrder(token, identifier, clientid) {
  const axios = create();

  return await axios.post("/api/my-payments/add-from-code", {
    identifier,
    clientid
  });
}

export async function updateOrder(token, values) {
  const axios = createUsingBearerToken(token);

  return await axios.put("/api/orders/update", values);
}

export async function getOrder(token, id) {
  const axios = createUsingBearerToken(token);

  return await axios.get(`/api/orders/get`, { params: { id } });
}

export async function deleteOrder(token, id) {
  const axios = createUsingBearerToken(token);

  return await axios.delete(`/api/orders/delete`, { params: { id } });
}

export async function deleteOrderItem(token, id) {
  const axios = createUsingBearerToken(token);

  return await axios.delete(`/api/orders/deleteitem`, { params: { id } });
}

export async function getAllOrder(token, query) {
  const axios = createUsingBearerToken(token);

  let response;
  try {
    response = await axios.post("/api/orders/all", query);
  } catch (e) {
    throw new Error("Error al recuperar las ordenes de pago.");
  }

  return response.data;
}

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  withStyles
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { default as React, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { getSessionToken } from "../../../actions/auth-actions";
import { apiErrorHandler } from "../../../api/api-error-handler";
import { getConfiguration, postConfiguration } from '../../../api/configuration-api';
import ImageConcept from "../../../assets/img/concepts-form-img.JPG";
import { withBasicLayout } from "../../layout/basic-layout";
import CancelConfirmDialog from "../../ui/cancel-confirm-dialog";
import styles from "./styles";


const AdminConfigurationForm = (props) => {
  const { classes } = props;

  const history = useHistory();

  const [showDialog, setShowDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  const back = () => history.push("/");

  const submitHandler = async (values, helpers) => {
    const { setSubmitting } = helpers;
    try {
      const token = getSessionToken();
      await postConfiguration(token, values);
      toast.success("Guardado exitoso");
    } catch (error) {
      apiErrorHandler(error, helpers);
      setSubmitting(false);
    }
  };

  const getInitialValues = async () => {
    try {
      const token = getSessionToken();
      const response = await getConfiguration(token);

      setInitialValues(response.data);
      setShowForm(true);
    } catch (error) {

      // TODO: Añadir handler para manejo de este tipo de errores.
      console.log(error);
    }
  };

  const validation = Yup.object({
    merchantcode: Yup.string().required("Campo requerido"),
    currency: Yup.string()
      .matches(
      /[A-Z]+/,
      "La contraseña solo puede tener caracteres alfabéticos (A-Z) en mayúsculas.")
      .length(3, "La longitud del código debe de ser de 3 caracteres")
      .required("Campo requerido"),
    merchantname: Yup.string().required("Campo requerido"),
    merchantaddress: Yup.string().required("Campo requerido"),
    merchantphone: Yup.string()
      .matches(/^\d{10}$/, 'El valor introducido debe ser solo numérico, sin simbolos especiales.')
      .length(10, "El valor debe consistir de 10 caracteres.")
      .required("Campo requerido"),
    merchantemail: Yup.string()
      .email("Correo electrónico inválido.")
      .required("Campo requerido"),
    integrationid: Yup.string().required("Campo requerido"),
    integrationpassword: Yup.string().required("Campo requerido"),
    integrationendpoint: Yup.string().required("Campo requerido"),
    merchantscript: Yup.string().required("Campo requerido"),
  });

  if (!initialValues) {
    getInitialValues();
  }

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card className={classes.root}>
                <CardHeader
                  title={"Configuración de plataforma de pagos"}
                  subheader="Administre los parámetros de configuración de la plataforma de pagos."
                />
                <CardContent>
                  {!showForm && (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  )}
                  {showForm && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validation}
                      onSubmit={submitHandler}
                    >
                      {({ isSubmitting, status }) => {
                        return (
                          <Form>
                            {status && (
                              <Box mt={4} mb={2}>
                                <Alert variant="outlined" severity="error">
                                  {status}
                                </Alert>
                              </Box>
                            )}


                            <Box mb={4}>
                              <Grid container spacing={3}>

                                <Grid item xs={12} sm={12}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    name="merchantscript"
                                    label="URL de script para Hosted Checkout"
                                    helperText="URL de script JS para Hosted Checkout. Ejemplo: https://evopaymentsmexico.gateway.mastercard.com/checkout/version/57/checkout.js"
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    name="merchantcode"
                                    label="Merchant Code"
                                    helperText="Código provisto por la pasarela de pago."
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    name="currency"
                                    label="Código de divisa"
                                    helperText="Expresada como un código alfabético ISO4217. Por ejemplo: MXN para pesos mexicanos."
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    name="merchantname"
                                    label="Nombre del comercio"
                                    helperText="Nombre del comercio como se mostrará en la pasarela de pagos."
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    name="merchantaddress"
                                    label="Domicilio del comercio"
                                    helperText="Domicilio del comercio como se mostrará en la pasarela de pagos."
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    name="merchantphone"
                                    label="Teléfono del comercio"
                                    helperText="Teléfono del comercio como se mostrará en la pasarela de pagos."
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="email"
                                    name="merchantemail"
                                    label="Correo electrónico del comercio"
                                    helperText="Correo electrónico del comercio como se mostrará en la pasarela de pagos."
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    name="integrationid"
                                    label="ID de usuario/operador de API"
                                    helperText="Este usuario se utiliza para validar operaciones mediante REST API."
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="password"
                                    name="integrationpassword"
                                    label="Contraseña de usuario/operador de API"
                                    helperText="Contraseña del usuario/operador de API."
                                    required
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    name="integrationendpoint"
                                    label="URL de integracion REST. "
                                    helperText="URL base de integración REST. En esta URL se consultan las operaciones: https://evopaymentsmexico.gateway.mastercard.com/api/rest/version/57. No debe terminar con diagonal."
                                    required
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <Button
                                  type="button"
                                  fullWidth
                                  variant="contained"
                                  color="inherit"
                                  className={classes.submit}
                                  disabled={isSubmitting}
                                  onClick={() => setShowDialog(true)}
                                >
                                  Volver
                                </Button>
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  disabled={isSubmitting}
                                >
                                  Guardar
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Hidden smDown>
              <Grid item md={4}>
                <div className={classes.boxImageConcept}>
                  <img
                    className={classes.imageConcept}
                    src={ImageConcept}
                    alt="Configuración de plataforma"
                  />
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <CancelConfirmDialog
        hide={() => setShowDialog(false)}
        handleAccept={() => {
          back();
        }}
        open={showDialog}
      />
    </>
  )
}

AdminConfigurationForm.propTypes = {

}

export default withBasicLayout(withStyles(styles)(AdminConfigurationForm));

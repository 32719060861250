import { withStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Settings } from '@material-ui/icons';
import ListIcon from "@material-ui/icons/List";
import PeopleIcon from "@material-ui/icons/People";
import ReceiptIcon from "@material-ui/icons/Receipt";
import React from "react";
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { useDispatch, useSelector } from "react-redux";
import {
  hideAdminMenuAction,
  showAdminMenuAction
} from "../../actions/admin-actions";
import { AppState } from "../../store/configure-store";
import styles from "./admin-drawer-styles";

function AdminDrawer(props: any) {
  const { classes, history } = props;

  const dispatch = useDispatch();

  const showMenu = useSelector((state: AppState) => state.admin.showMenu);

  const hideDrawer = () => dispatch(hideAdminMenuAction());
  const showDrawer = () => dispatch(showAdminMenuAction());

  const list = () => (
    <div onClick={hideDrawer} onKeyDown={hideDrawer}>
      <List className={classes.listStyles}>
        <ListItem
          button
          onClick={() => {
            history.push("/admin/clients");
          }}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText
            primary="Clientes"
            secondary="Administre sus cuentas de cliente y genere ordenes de pago."
          />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/admin/concepts");
          }}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText
            primary="Conceptos"
            secondary="Conceptos para añadir a tus ordenes de pago"
          />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/admin/templates");
          }}
        >
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText
            primary="Plantillas de orden de pago"
            secondary="Crea o edita plantillas que tus clientes pueden seleccionar."
          />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/admin/orders");
          }}
        >
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText
            primary="Ordenes de pago"
            secondary="Crea o edita ordenes de pago de tus clientes"
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/admin/import");
          }}
        >
          <ListItemIcon>
            <SystemUpdateAltIcon />
          </ListItemIcon>
          <ListItemText primary="Importar datos" secondary="Importar y actualizar registros de clientes, conceptos u ordenes de pago." />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/admin/config");
          }}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText
            primary="Configuración"
            secondary="Configuración global de la aplicación "
          />
        </ListItem>

      </List>
      <Divider />
    </div>
  );

  return (
    <SwipeableDrawer
      anchor={"left"}
      open={showMenu}
      variant={"temporary"}
      onClose={hideDrawer}
      onOpen={showDrawer}
    >
      {list()}
    </SwipeableDrawer>
  );
}

export default withStyles(styles)(AdminDrawer);

import {
  Container,
  Grid
} from "@material-ui/core";
import clsx from 'clsx';
import React from "react";
import logoCentro from "../../../assets/img/logo-centro-white.png";
import logoCentroFox from "../../../assets/img/logotipo-centro-fox-white.png";
import "./styles.css";

function FooterRegistroMedico(props: any) {
  const { classes } = props;

  return (
    <div className={clsx(classes?.root, "no-print")}>
      <footer id="footer-seminario">
        <div className="footer-newsletter">
          <Container maxWidth="lg">
            <Grid container>
              <Grid item md={3} className="col-md-3 text-left">
                <span className="logo mr-auto"
                ><img
                    src={logoCentro}
                    alt=""
                    className="img-fluid"
                  /></span>
                <div className="espacio"></div>
                <p style={{
                  color: "#ffffff",
                  textDecoration: "none"
                }}>
                  <a href="tel:4777879419" target="_blank" style={{
                    color: "#ffffff"
                  }}>Tel: 477 78 79 419 </a>
                  <br />
                  <a href="mailto:medicaltourism@uiwtx.edu;" style={{
                    color: "#ffffff"
                  }}>medicaltourism@uiwtx.edu</a>
                </p>
              </Grid>

              <Grid item md={3}>
                <div className="menuColumn menuLeft">
                  <ul>
                    <li>
                      <a href="https://my.uiw.edu/ada/index.html" target="_self"
                      >ADA/Section 504</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.uiw.edu/finaid/Policies.html"
                        target="_self"
                      >Consumer Information</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.uiw.edu/contact/index.html"
                        target="_self"
                      >Contact UIW</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://my.uiw.edu/faculty/index.html"
                        target="_self"
                      >Faculty</a
                      >
                    </li>
                    <li>
                      <a href="https://my.uiw.edu/staff/index.html" target="_self"
                      >Staff</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://map.concept3d.com/?id=361#!"
                        target="_blank"
                        title="Facilities and Map opens in a new window"
                      >Facilities and Map</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://my.uiw.edu/police/index.html"
                        target="_self"
                      >University Police</a
                      >
                    </li>
                  </ul>
                </div>
              </Grid>

              <Grid item md={3}>
                <div className="menuColumn menuLeft">
                  <ul>
                    <li>
                      <a
                        href="https://my.uiw.edu/library/index.html"
                        target="_self"
                      >UIW Libraries</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://my.uiw.edu/student-advocacy-and-accountability/report-an-incident.html"
                        target="_self"
                      >Report An Incident</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.uiw.edu/giving/index.html"
                        target="_self"
                      >Give To UIW</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.uiw.edu/privacy-policy-and-disclaimer.html"
                        target="_self"
                      >Privacy Policy and Disclaimer</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://my.uiw.edu/titleix/index.html"
                        target="_self"
                      >Title IX</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://jobs.uiw.edu/"
                        target="_blank"
                        title="Employment opens in a new window"
                      >Employment</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.uiw.edu/covid-19/index.html"
                        target="_self"
                      >Coronavirus Update</a
                      >
                    </li>
                  </ul>
                </div>
              </Grid>

              <Grid item md={3}>
                <span className="logo mr-auto"
                ><img
                    src={logoCentroFox}
                    alt=""
                    className="img-fluid"
                  /></span>
              </Grid>

            </Grid>
          </Container>
        </div>
      </footer>
    </div>
  );
}

export default FooterRegistroMedico;

import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  withStyles,
} from "@material-ui/core";
import clsx from 'clsx';
import PropTypes from "prop-types";
import React from "react";
import logo from "../../assets/img/logo.png";
import sisters from "../../assets/img/sisters.png";
import styles from "./footer-styles";

function Footer(props) {
  const { classes } = props;

  return (
    <div className={clsx(classes.root, "no-print")}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid className={classes.widgetBox} item xs={12} sm={6} md={3}>
            <img src={logo} className={classes.widgetLogo} alt="logo" />
            <img src={sisters} className={classes.widgetLogo} alt="logo" />
          </Grid>
          <Grid className={classes.widgetBox} item xs={12} sm={6} md={3}>
            <Typography variant="h5">Contáctanos</Typography>
            <p>
              Email:
              <Link href="mailto:informes@uiwbajio.mx" className={classes.link}>
                informes@uiwbajio.mx
              </Link>
            </p>
            <p>
              Teléfonos: <br />
              Campus &nbsp;
              <Link href="tel: +52 (462) 478 3200" className={classes.link}>
                +52 (462) 478 3200
              </Link>
              <br />
              Admisiones &nbsp;
              <Link href="tel: +52 (462) 251 9679" className={classes.link}>
                +52 (462) 251 9679
              </Link>
              <br />
              Posgrados y Formación Continua &nbsp;
              <Link href="tel: +52 (462) 148 1135" className={classes.link}>
                +52 (462) 148 1135
              </Link>
            </p>
            <p>
              Carretera Panamericana #6553
              <br />
              Tramo Irapuato-Silao km 121.2
            </p>
          </Grid>
          <Grid className={classes.widgetBox} item xs={12} sm={6} md={3}>
            <Typography variant="h5">Horarios</Typography>
            <p>
              Lunes a Viernes: <br />
              9:00 A.M. – 2:00 P.M.
              <br />
              3:00 P.M. – 6:00 P.M.
            </p>
            <p>
              Sábados:
              <br />
              9:00 A.M. – 12:00 P.M.
            </p>
          </Grid>
          <Grid className={classes.widgetBox} item xs={12} sm={6} md={3}>
            <Box pb={2}>
              <Typography variant="h5">Campus Incarnate Word</Typography>
            </Box>
            <Link
              target="_blank"
              href="https://www.uiw.edu/"
              className={classes.linkBox}
            >
              Campus San Antonio, Texas.
            </Link>
            <Link
              target="_blank"
              href="https://www.ciw.edu.mx/"
              className={classes.linkBox}
            >
              Campus Ciudad de México, México.
            </Link>
            <Link
              target="_blank"
              href="https://www.studyabroad-germany.eu/"
              className={classes.linkBox}
            >
              Campus Heidelberg, Alemania.
            </Link>
            <Link
              target="_blank"
              href="https://www.studyabroad-france.eu/"
              className={classes.linkBox}
            >
              Campus Estrasburgo, Francia.
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Box className={classes.footerBottom}>
          <Box flexGrow="2">
            © {new Date().getFullYear()} Universidad Incarnate Word Campus
            Bajío. Todos los derechos reservados.
          </Box>
          <Box className={classes.footerLinks}>
            <Box flexGrow="1">
              <Link target="_blank" href="#">
                Politicas de servicio
              </Link>
            </Box>
            <Box flexGrow="1">
              <Link target="_blank" href="#">
                Politicas de reembolso o cancelación
              </Link>
            </Box>
            <Box flexGrow="1">
              <Link
                target="_blank"
                href="https://uiwbajio.mx/reglamento-general-licenciaturas"
              >
                Reglamento general de Licenciaturas
              </Link>
            </Box>
            <Box flexGrow="1">
              <Link target="_blank" href="https://uiwbajio.mx/privacidad">
                Aviso de privacidad
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(Footer);

export default (theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    minHeight: "70vh",
  },
  instructions: {
    marginBottom: theme.spacing(4),
    textAlign: "justify",
    "& h5": {
      textAlign: "center",
    },
  },
  submitBtn: {
    marginTop: theme.spacing(2),
    maxWidth: 150,
    width: "100%",
  },
  logoHome: {
    display: "block",
    margin: "0 auto",
    left: -20,
    position: "relative",
    maxWidth: 300,
    width: "90%",
  },
  captchaContainer: {
    padding: theme.spacing(3, 0, 2),
  },
});
